import React from 'react';
import PropTypes from 'prop-types';

export default function FormTitleMapping({ element, animationDelay }) {
    const { config } = element;

    return (
        <div className="TitleElement" style={{ animationDelay: `${animationDelay}ms` }}>
            <h1 key="title">{config.title}</h1>
            <p key="description">{config.description}</p>
        </div>
    );
}

FormTitleMapping.propTypes = {
    element: PropTypes.shape({
        config: PropTypes.shape({ title: PropTypes.string, description: PropTypes.string }).isRequired,
    }).isRequired,
    animationDelay: PropTypes.number.isRequired,
};

FormTitleMapping.defaultProps = {};
