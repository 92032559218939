import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import codeMirror from 'codemirror';
import ClassNames from 'classnames';

// importing each of these enables that language mode, otherwise the mode option doesn't work.
// list of all language modes: https://codemirror.net/mode/
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/julia/julia';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/python/python';
import 'codemirror/mode/r/r';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/yaml/yaml';

import 'codemirror/addon/selection/active-line';

require('./styles.scss');

const fileExtensionModes = {
    css: 'css',
    html: 'htmlmixed',
    jl: 'julia',
    js: 'javascript',
    md: 'markdown',
    py: 'python',
    r: 'r',
    sql: 'sql',
    yml: 'yaml',
    yaml: 'yaml',
};

export default function Codebox(props) {
    const { value, language, fileExtension, readOnly, id, onChange, onBlur } = props;
    const mode = language || fileExtensionModes[fileExtension] || 'text';
    const codeboxContainerRef = useRef(null);
    const [codeMirrorInstance, setCodeMirrorInstance] = useState(null);

    useEffect(() => {
        if (!codeMirrorInstance && codeboxContainerRef.current) {
            const instance = codeMirror(codeboxContainerRef.current, {
                value,
                mode,
                lineNumbers: true,
                readOnly: readOnly ? 'nocursor' : false,
                styleActiveLine: true,
                lineWrapping: true,
                showCursorWhenSelecting: !readOnly,
            });

            instance.on('change', (editorInstance) => {
                onChange(id, editorInstance.getValue());
            });

            instance.on('blur', (editorInstance) => {
                onBlur(id, editorInstance.getValue());
            });

            setCodeMirrorInstance(instance);
        }
    }, [codeboxContainerRef, codeMirrorInstance, id, language, fileExtension, readOnly, onChange, onBlur, mode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Only update if the current value is different from the incoming value
        if (codeMirrorInstance && codeMirrorInstance.getValue() !== value) {
            codeMirrorInstance.setValue(value);
        }
    }, [value, codeMirrorInstance]);

    useEffect(() => {
        if (codeMirrorInstance) {
            codeMirrorInstance.setOption('mode', mode);
        }
    }, [mode, codeMirrorInstance]);

    return <div className={ClassNames('Codebox', { 'Codebox-readOnly': readOnly })} ref={codeboxContainerRef} />;
}

Codebox.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    // language must be one of the following supported language modes:
    // 'css','htmlmixed','javascript','julia','markdown','python','r','sql','yaml'
    // (more can be added, see above)
    language: PropTypes.string,
    fileExtension: PropTypes.string,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};
Codebox.defaultProps = {
    value: '',
    language: null,
    fileExtension: '',
    readOnly: false,
    onChange: () => {},
    onBlur: () => {},
};
