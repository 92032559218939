/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { scrollHelpers } from '../../../helpers/scroll';
import { componentRootAppended } from '../../../helpers/component';
import { addRootClickHandler, removeRootClickHandler, getHierarchicalClickHandler } from '../../../helpers/clickOut';
import { directions, getStyles } from './helpers';

require('./styles.scss');

export default class ContextMenu extends Component {
    constructor(props) {
        super(props);
        this.domRoot = componentRootAppended('context-menu-root');
        this.state = {
            contextStyle: { top: 0, left: 0 },
            tipStyle: undefined,
            position: undefined,
        };
        this.scrollHelpers = scrollHelpers(this.preventScrollOutsideOfContextMenu);
    }
    componentDidMount = () => {
        const { open, initialFocusRef } = this.props;
        addRootClickHandler(this.handleClickOut);
        if (open) {
            if (initialFocusRef) this.initialFocusDiv = initialFocusRef;
            this.calculateStyles();
            this.disableScroll();
            this.focusInitial();
        }
    };
    componentDidUpdate = (prevProps) => {
        const { open, initialFocusRef } = this.props;
        this.calculateStyles(prevProps);

        if (open && !prevProps.open) {
            if (initialFocusRef) this.initialFocusDiv = initialFocusRef;
            this.disableScroll();
            this.focusInitial();
        } else if (prevProps.open && !open) {
            this.enableScroll();
        }
    };
    componentWillUnmount = () => {
        removeRootClickHandler(this.handleClickOut);
        this.enableScroll();
    };

    get stopPropagationHandlers() {
        const stopPropagation = (e) => {
            e.stopPropagation();
        };

        return {
            onKeyDown: (e) => {
                if (e.key === 'Escape') this.handleClickOut();
                e.stopPropagation();
            },
            onKeyPress: stopPropagation,
            onKeyUp: stopPropagation,
            onClick: (e) => {
                if (e.target === this.hookRef) this.handleClickOut();
                e.stopPropagation();
            },
            onContextMenu: stopPropagation,
            onMouseDown: (e) => {
                const handlers = getHierarchicalClickHandler(this.handleClickOut);
                handlers.forEach((handler) => handler(e));
                e.stopPropagation();
            },
        };
    }

    handleClickOut = (e) => {
        const { open, onClickOut } = this.props;
        if (!open) return;
        onClickOut(e);
    };

    setHookRef = (node) => {
        this.hookRef = node;
    };
    setContextMenuRef = (node) => {
        this.contextMenuRef = node;
    };

    getContentClientRect = () => this.contextMenuRef?.getBoundingClientRect();
    getHookClientRect = () => {
        const { coordinates } = this.props;
        if (coordinates)
            return { left: coordinates.x, right: coordinates.x, top: coordinates.y, bottom: coordinates.y };
        return this.hookRef?.getBoundingClientRect();
    };

    calculateStyles = (prevProps) => {
        const { hasTip, open, position: propsPosition } = this.props;
        const { position: prevPropsPosition } = prevProps || {};
        const { contextStyle: prevStyle, position: statePosition } = this.state;
        const [contextStyle, tipStyle, position] = getStyles(
            propsPosition,
            this.getHookClientRect(),
            this.getContentClientRect(),
            hasTip,
            open,
            prevPropsPosition === propsPosition ? statePosition : undefined, // keep the old position if possible, but not if the props have changed
        );
        if (directions.some((dir) => contextStyle[dir] !== prevStyle[dir])) {
            // set the state if the position of the context menu has changed
            // note: this could happen on componentDidMount and componentDidUpdate
            this.setState({ contextStyle, tipStyle, position });
        }
    };

    disableScroll = () => this.scrollHelpers.disableScroll();
    enableScroll = () => this.scrollHelpers.enableScroll();

    preventScrollOutsideOfContextMenu = (e = window.event) => {
        if (e.target && this.domRoot.contains(e.target)) return;
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
    };

    setInitialFocusRef = (el) => {
        this.initialFocusDiv = el;
    };
    focusInitial = () => {
        if (this.initialFocusDiv) this.initialFocusDiv.focus();
    };
    focusTop = () => {
        if (this.contextMenuRef) {
            const el = this.contextMenuRef.querySelector('.ContextMenuItem');
            if (el) el.focus();
            else if (this.initialFocusDiv) this.initialFocusDiv.focus();
        }
    };

    render() {
        const { open, children, hasTip, listHeading, className, onMouseLeave } = this.props;
        const { contextStyle, tipStyle } = this.state;

        return (
            <div
                className={ClassNames('ContextMenu__hook', { 'ContextMenu__hook--open': open })}
                {...this.stopPropagationHandlers}
                ref={this.setHookRef}
            >
                {open &&
                    createPortal(
                        <div
                            onMouseLeave={onMouseLeave}
                            className={ClassNames('ContextMenu', className)}
                            style={contextStyle}
                            ref={this.setContextMenuRef}
                        >
                            {listHeading ? <div className="ContextMenu__ListHeading">{listHeading}</div> : null}
                            {children}
                            {hasTip ? <div className="ContextMenu__tip" style={tipStyle} /> : null}
                            <div tabIndex={-1} ref={this.setInitialFocusRef} />
                            <div tabIndex={0} onFocus={this.focusTop} role="none" />
                        </div>,
                        this.domRoot,
                    )}
            </div>
        );
    }
}

ContextMenu.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    onClickOut: PropTypes.func,
    position: PropTypes.string,
    coordinates: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    hasTip: PropTypes.bool,
    listHeading: PropTypes.node,
    className: PropTypes.string,
    onMouseLeave: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    initialFocusRef: PropTypes.object, // is actually a DOM element
};

ContextMenu.defaultProps = {
    position: 'bottom left',
    onClickOut: () => {},
    coordinates: undefined,
    hasTip: false,
    listHeading: null,
    className: null,
    onMouseLeave: undefined,
    initialFocusRef: null,
};
