import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { Input } from '../../../shared';

const createOptions = (optionsString) =>
    (optionsString || '').split(',').map((item) => ({ label: item.trim(), value: item.trim() }));

const createBinaryOptions = (binaryText) => [
    { label: binaryText?.true || 'True', value: true },
    { label: binaryText?.false || 'False', value: false },
];

const getFormInputProps = (type, config) => {
    switch (type) {
        case 'binary':
            return { type: 'radio', options: createBinaryOptions(config.binaryText) };
        case 'checkbox':
            return {
                type: 'multiCheckbox',
                options: createOptions(config.options),
                value: createOptions(config.options).map(() => false),
            };
        case 'dropdown':
            return {
                type: 'select',
                options: createOptions(config.options),
            };
        case 'longText':
            return { type: 'textarea' };
        case 'number':
            return { type: 'number' };
        case 'radio':
            return { type: 'radio', options: createOptions(config.options) };
        case 'ratingScale': {
            const [min, max] = config.scale ? config.scale.split(':') : [];
            return {
                type: 'scale',
                min: min ?? 1,
                minLabel: config.scaleText?.left,
                max: max ?? 10,
                maxLabel: config.scaleText?.right,
            };
        }
        case 'shortText':
            return { type: 'text' };
        case 'date':
            return { type: 'date' };
        case 'time':
            return { type: 'time' };
        default:
            return {};
    }
};

export default function FormQuestion({ element, animationDelay, ...formProps }) {
    const { type, config } = element;
    const { question, description, required } = config;
    const inputProps = getFormInputProps(type, config);

    return (
        <div
            className={ClassNames('QuestionElement', {
                'QuestionElement--required': required,
                'QuestionElement--error': formProps?.errors?.length,
            })}
            style={{ animationDelay: `${animationDelay}ms` }}
        >
            <h3>{question}</h3>
            <p>{description}</p>
            <Input {...inputProps} {...formProps} />
        </div>
    );
}

FormQuestion.propTypes = {
    element: PropTypes.shape({
        type: PropTypes.string.isRequired,
        config: PropTypes.shape({
            question: PropTypes.string,
            description: PropTypes.string,
            required: PropTypes.bool,
        }).isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    animationDelay: PropTypes.number.isRequired,
};

FormQuestion.defaultProps = {};
