import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import chroma from 'chroma-js';
import IconButton from '../IconButton';
import Icon from '../Icon';

require('./styles.scss');

export default function NuggetSkin({
    canDrag,
    color,
    label,
    onRemove,
    removable,
    squareRight,
    icon,
    isPreview,
    isDragging,
    forwardRef,
}) {
    const style = color && {
        backgroundColor: color,
        borderColor: chroma(color).darken(0.5).hex(),
    };
    return (
        <div
            ref={forwardRef}
            className={ClassNames('Nugget', {
                'Nugget--canDrag': canDrag,
                'Nugget--cantDrag': !canDrag,
                'Nugget--colored': color,
                'Nugget--dragging': isDragging || isPreview,
                'Nugget--squareRight': squareRight,
            })}
            style={style}
        >
            {icon && <Icon icon={icon} size="12px" />}
            <div className="Nugget__label">{label}</div>
            {removable && <IconButton icon="times" onClick={onRemove} mini />}
        </div>
    );
}

NuggetSkin.propTypes = {
    label: PropTypes.string,
    canDrag: PropTypes.bool,
    removable: PropTypes.bool,
    onRemove: PropTypes.func,
    squareRight: PropTypes.bool,
    color: PropTypes.string,
    icon: PropTypes.string,
    isPreview: PropTypes.bool,
    isDragging: PropTypes.bool,
    forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

NuggetSkin.defaultProps = {
    canDrag: true,
    removable: true,
    onRemove: null,
    squareRight: false,
    color: null,
    isPreview: false,
    isDragging: false,
    forwardRef: undefined,
    label: '',
    icon: null,
};
