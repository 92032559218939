import { useState, useMemo } from 'react';

export function usePreviewItems(id, items, { isStatic } = {}) {
    // takes an array of items, and inserts a temporary item into the array to preview next state.
    // return value: [previewItems, setPreviewItem, tempItem]
    //  - previewItems: an array of items (with the tempItem inserted at tempIndex)
    //  - setPreviewItem: function(item, index).  Set the tempItem and the tempIndex if changed
    //  - tempItem: object.  The temporary item.

    const [tempItem, setTempItem] = useState(null);
    const [tempIndex, setTempIndex] = useState(null);

    const setPreviewItem = (item, index) => {
        if (item && tempItem && item.id === tempItem.id && index === tempIndex) return;
        setTempItem(item);
        setTempIndex(index);
    };

    const previewItems = useMemo(() => {
        if (!tempItem || typeof tempIndex !== 'number') return items;
        let newPreviewItems;
        if (tempItem.containerId === id) {
            newPreviewItems = items.filter((item) => item.id !== tempItem.id);
        } else {
            newPreviewItems = [...items];
        }
        newPreviewItems.splice(tempIndex, 0, tempItem);
        return newPreviewItems;
    }, [items, tempItem, tempIndex, id]);

    if (isStatic) return [null, () => {}, {}]; // unfortunately, with hooks, this if statement cannot go at the top.
    return [previewItems, setPreviewItem, tempItem];
}
