import ButtonList from './ButtonList';
import Checkbox from './Checkbox';
import Codebox from './Codebox';
import Date from './Date';
import EditableText from './EditableText';
import EditableTextArea from './EditableTextArea';
import File from './File';
import Json from './Json';
import List from './List';
import Number from './Number';
import MultiCheckbox from './MultiCheckbox';
import MultiSelect from './MultiSelect';
import Password from './Password';
import Radio from './Radio';
import Scale from './Scale';
import Search from './Search';
import Select from './Select';
import SelectButtons from './SelectButtons';
import Slider from './Slider';
import SliderText from './SliderText';
import Switch from './Switch';
import Text from './Text';
import TextArea from './TextArea';
import Time from './Time';

export const inputFields = {
    buttonList: ButtonList,
    checkbox: Checkbox,
    codebox: Codebox,
    date: Date,
    'editable-text': EditableText,
    'editable-textarea': EditableTextArea,
    file: File,
    json: Json,
    list: List,
    number: Number,
    multiCheckbox: MultiCheckbox,
    multiSelect: MultiSelect,
    password: Password,
    radio: Radio,
    scale: Scale,
    search: Search,
    select: Select,
    selectButtons: SelectButtons,
    slider: Slider,
    'slider-text': SliderText,
    switch: Switch,
    textarea: TextArea,
    text: Text,
    time: Time,
};
