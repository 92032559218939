import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class ContextMenuItem extends PureComponent {
    handleClick = (e) => {
        const { onClick, id } = this.props;
        e.preventDefault();
        e.stopPropagation();
        onClick(id, e);
    };
    handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === ' ') this.handleClick(e);
    };

    render() {
        const { name, type, rightChildren, className, style, active, children, ...htmlAttributes } = this.props;

        return (
            <div
                onKeyPress={this.handleKeyPress}
                {...htmlAttributes}
                className={ClassNames('ContextMenuItem', className, { 'ContextMenuItem--active': active })}
                onClick={this.handleClick}
                role="menuitem"
                tabIndex={0}
                style={style}
            >
                {type && <span className="ContextMenuItem__type"> {type} </span>}
                <span className="ContextMenuItem__name"> {name} </span>
                {rightChildren && <span className="ContextMenuItem__rightChildren"> {rightChildren} </span>}
                {children}
            </div>
        );
    }
}

ContextMenuItem.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    active: PropTypes.bool,
    type: PropTypes.node,
    rightChildren: PropTypes.node,
    style: PropTypes.shape({}),
    onClick: PropTypes.func,
    children: PropTypes.node, // used for confirm delete menu and nested menus
};
ContextMenuItem.defaultProps = {
    className: '',
    name: '',
    type: null,
    active: false,
    rightChildren: null,
    style: {},
    onClick: () => {},
    children: null,
};
