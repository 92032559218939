import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class SelectButtons extends Component {
    handleClick = (optionId, e) => {
        const { onChange, id } = this.props;
        e.preventDefault();
        e.stopPropagation();
        onChange(id, optionId);
    };

    render() {
        const { value, options, dark, disabled, gray, errors } = this.props;
        const selectedItem = options.find((item) => item.value === value);
        return (
            <div
                className={ClassNames('SelectButtons', {
                    'SelectButtons--dark': dark,
                    'SelectButtons--gray': gray,
                    'SelectButtons--light': !(dark || gray),
                    'SelectButtons--error': errors.length > 0,
                    'SelectButtons--disabled': disabled,
                })}
            >
                {options.map((item) => (
                    <button
                        type="button"
                        key={item.id || item.value}
                        id={item.id || item.value}
                        className={ClassNames('SelectButtons__button', {
                            'SelectButtons__button--selected': selectedItem === item,
                        })}
                        onClick={(e) => this.handleClick(item.id || item.value, e)}
                    >
                        {item.label}
                    </button>
                ))}
            </div>
        );
    }
}
SelectButtons.propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            id: PropTypes.string,
        }),
    ),
    disabled: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
};

SelectButtons.defaultProps = {
    value: '',
    options: [],
    dark: false,
    gray: false,
    disabled: false,
    errors: [],
};
