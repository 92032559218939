import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Slider from '../Slider';
import TextInput from '../Text';

require('./styles.scss');

export default class SliderText extends Component {
    constructor(props) {
        super(props);
        const { value } = props;
        this.state = { slider: true, value };
    }
    toggleSlider = () => {
        const { slider } = this.state;
        this.setState({ slider: !slider });
    };
    handleChange = (id, value) => {
        const { min, max, onChange } = this.props;
        this.setState({ value });
        const numberValue = Number(value);
        if (!Number.isNaN(numberValue)) {
            if (numberValue >= min && numberValue <= max) {
                onChange(id, value);
            }
        }
    };
    render() {
        const { labelButton } = this.props;
        const { slider, value } = this.state;
        return (
            <div className="SliderText">
                <span className="SliderText__labelButton" onClick={this.toggleSlider} role="button" tabIndex={-1}>
                    {labelButton}
                </span>
                {slider ? (
                    <Slider {...this.props} value={Number(value) || 0} onChange={this.handleChange} />
                ) : (
                    <TextInput {...this.props} value={value} onChange={this.handleChange} autoFocus />
                )}
            </div>
        );
    }
}

SliderText.propTypes = {
    value: PropTypes.number,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    labelButton: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};
SliderText.defaultProps = {
    value: 0,
    onChange: () => {},
};
