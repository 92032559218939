import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class TextButton extends PureComponent {
    handleClick = (e) => {
        const { onClick, id } = this.props;
        e.preventDefault();
        onClick(id);
    };

    render() {
        const { tabIndex, gray, blue, disabled, children } = this.props;
        return (
            <span
                role="button"
                tabIndex={tabIndex}
                onClick={this.handleClick}
                className={ClassNames('TextButton', {
                    'TextButton--gray': gray,
                    'TextButton--blue': blue || !gray,
                    'TextButton--disabled': disabled,
                })}
            >
                {children}
            </span>
        );
    }
}

TextButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    blue: PropTypes.bool,
    gray: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    tabIndex: PropTypes.number,
};

TextButton.defaultProps = {
    blue: false,
    gray: false,
    disabled: false,
    id: '',
    tabIndex: 0,
    onClick: () => {},
};
