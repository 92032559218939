import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Button from '../Button';

require('./styles.scss');

export default function WorkflowStepper(props) {
    const { tabIndex, tabs } = props;
    const { backButton, forwardButton, complete } = tabs[tabIndex];
    return (
        <header className="WorkflowStepper">
            {backButton && (
                <Button gray {...backButton} className="WorkflowStepper__backButton">
                    {backButton.content}
                </Button>
            )}
            {tabs.map((tab, index) => [
                <div
                    key={tab.path || tab.label}
                    className={ClassNames('WorkflowStepper__tab', {
                        'WorkflowStepper__tab--previous': index < tabIndex,
                        'WorkflowStepper__tab--active': index === tabIndex,
                        'WorkflowStepper__tab--complete': tab.complete,
                    })}
                >
                    <div className="WorkflowStepper__step">
                        <div className="WorkflowStepper__lines">
                            <div className="WorkflowStepper__line" />
                            <div className="WorkflowStepper__line" />
                        </div>
                        {index === tabIndex && <div className="WorkflowStepper__midCircle" />}
                    </div>
                    <div className="WorkflowStepper__tabLabel">{tab.label}</div>
                </div>,
            ])}
            {forwardButton && (
                <Button
                    blue
                    disabled={complete === undefined ? false : !complete}
                    {...forwardButton}
                    className="WorkflowStepper__forwardButton"
                >
                    {forwardButton.content}
                </Button>
            )}
        </header>
    );
}

WorkflowStepper.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            complete: PropTypes.bool,
            backButton: PropTypes.shape({
                content: PropTypes.node,
            }),
            forwardButton: PropTypes.shape({
                content: PropTypes.node,
            }),
        }),
    ).isRequired,
    tabIndex: PropTypes.number.isRequired,
};

WorkflowStepper.defaultProps = {};
