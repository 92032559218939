/* eslint-disable no-nested-ternary */
import {
    white,
    neutral98,
    neutral90,
    neutral80,
    neutral70,
    neutral60,
    neutral50,
    neutral40,
    neutral30,
    neutral20,
    blue400,
    blue500,
    blue600,
    red400,
} from '../../../../styles/partials/colors';

export default function styleBasedOnProps(props) {
    return {
        control: (base, state) => {
            // the outermost container.
            const wwStyles = {
                paddingLeft: '8px',
                fontSize: '14px',
                boxShadow: state.isDisabled
                    ? `none`
                    : state.isFocused
                    ? `inset 0 0 0 1px ${blue500}`
                    : props.error
                    ? `inset 0 0 0 1px ${red400}`
                    : `inset 0 0 0 1px ${neutral90}`,
                backgroundColor: white,
                borderRadius: 5,
                borderColor: 'transparent',
                borderStyle: 'none',
                borderWidth: 0,
                '&:hover': { boxShadow: `inset 0 0 0 3px ${neutral90}` },
                '&:focus-within': { boxShadow: `inset 0 0 0 1px ${blue500}`, transition: 'none' },
                transition: '0.15s ease-in',
                minHeight: props.sm ? 25 : 40,
                minWidth: props.isClearable ? 180 : 160,
                height: props.sm ? 25 : undefined,
                cursor: 'pointer',
            };
            if (props.dark) {
                wwStyles.boxShadow = state.isDisabled
                    ? `none`
                    : state.isFocused
                    ? `inset 0 0 0 1px ${neutral50}`
                    : props.error
                    ? `inset 0 0 0 1px ${red400}`
                    : `inset 0 0 0 1px ${neutral80}`;
                wwStyles.backgroundColor = neutral50;
                wwStyles.color = white;
                wwStyles['&:hover'] = {
                    boxShadow: `inset 0 0 0 3px ${neutral80}`,
                };
                wwStyles['&:focus-within'] = {
                    boxShadow: `inset 0 0 0 1px ${neutral40}`,
                    backgroundColor: neutral98,
                    color: neutral30,
                };
            } else if (props.gray) {
                wwStyles.backgroundColor = neutral98;
            }
            return { ...base, ...wwStyles, ...(props.style || {}) };
        },
        menu: (base) => ({
            // the dropdown menu
            ...base,
            boxShadow: `0px 0px 6px ${neutral20}40`,
            backgroundColor: neutral50,
            border: `1px solid ${neutral70}`,
        }),
        indicatorSeparator: () => ({}),
        clearIndicator: (base, state) => {
            const wwStyles = {
                padding: 8,
                paddingRight: 2,
                color: state.isFocused ? blue500 : neutral80,
                '&:hover': { color: state.isFocused ? blue500 : neutral80 },
            };
            return { ...base, ...wwStyles };
        },
        dropdownIndicator: (base, state) => {
            const wwStyles = {
                padding: '2px 8px 2px 4px',
                DownChevron: {
                    size: 30,
                },
                color: state.isFocused ? blue500 : props.error ? red400 : neutral80,
                '&:hover': { color: state.isFocused ? blue500 : neutral80 },
                height: props.sm ? 25 : undefined,
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
                transition: 'all .2s ease',
            };
            return { ...base, ...wwStyles };
        },
        indicatorsContainer: (base) => {
            const wwStyles = {
                height: props.sm ? 25 : undefined,
            };
            return { ...base, ...wwStyles };
        },
        option: (base, state) => {
            const wwStyles = {
                color: white,
                backgroundColor: state.isDisabled
                    ? neutral98
                    : state.isSelected || state.isFocused
                    ? neutral40
                    : 'unset',
                '&:hover': { backgroundColor: neutral40 },
            };
            return { ...base, ...wwStyles };
        },

        noOptionsMessage: (base) => ({
            ...base,
            color: white,
        }),
        placeholder: (base) => {
            const wwStyles = {
                color: neutral60,
                margin: 0,
            };
            if (props.dark) wwStyles.color = white;
            return { ...base, ...wwStyles };
        },
        singleValue: (base) => {
            // this is the selected value in the textBox
            const wwStyles = { color: neutral40 };
            if (props.dark) {
                wwStyles.color = undefined;
            }
            return { ...base, ...wwStyles };
        },

        valueContainer: (base) => ({
            ...base,
            height: props.sm ? '25px' : '40px',
            padding: props.sm ? '0px' : '2px 8px 2px 4px',
        }),
        multiValue: (base) => {
            const wwStyles = {
                backgroundColor: blue400,
                border: `1px solid ${blue600}`,
                flexFlow: 'row-reverse',
                marginLeft: 6,
            };
            if (props.dark) {
                wwStyles.backgroundColor = blue400;
                wwStyles.border = `1px solid ${neutral20}`;
            }
            return { ...base, ...wwStyles };
        },
        multiValueLabel: (base) => {
            const wwStyles = {
                color: white,
                paddingLeft: 6,
                paddingRight: 8,
            };
            if (props.dark) wwStyles.color = neutral20;
            if (props.sm) {
                wwStyles.paddingTop = 1;
                wwStyles.paddingBottom = 1;
            }
            return { ...base, ...wwStyles };
        },
        multiValueRemove: (base) => {
            const wwStyles = {
                color: white,
                borderRight: `1px solid ${blue600}`,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                padding: '0 2px',
                transition: 'background-color 0.35s',
                '&:hover': { color: white, backgroundColor: blue500 },
            };
            if (props.dark) {
                wwStyles.color = neutral20;
                wwStyles.borderRight = `1px solid ${neutral20}`;
                wwStyles['&:hover'] = { color: neutral20, backgroundColor: blue500 };
            }
            return { ...base, ...wwStyles };
        },
        input: (base) => {
            if (props.dark) {
                return { ...base, color: white };
            }
            return base;
        },
    };
}
