import React from 'react';
import PropTypes from 'prop-types';

import QuestionElement from './question';
import TitleElement from './title';

import './styles.scss';
import { isQuestion } from '../helpers';

const elements = {
    title: TitleElement,
};

export default function FormElement(props) {
    const { element } = props;
    const Element = isQuestion(element) ? QuestionElement : elements[element.type];
    return <Element {...props} />;
}

FormElement.propTypes = {
    element: PropTypes.shape({ type: PropTypes.string, config: PropTypes.shape({}) }),
};

FormElement.defaultProps = {
    element: null,
};
