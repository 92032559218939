import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Icon from '../../Icon';

require('./styles.scss');

export default function Checkbox(props) {
    const { id, disabled, error, errors, dark, tabIndex, onChange, gray, value: propsValue } = props;
    const value = !!propsValue;

    return (
        <div
            role="checkbox"
            aria-checked={value}
            tabIndex={tabIndex}
            className={ClassNames('Checkbox', {
                'Checkbox--unselected': !value,
                'Checkbox--selected': value,
                'Checkbox--disabled': disabled,
                'Checkbox--error': error || errors.length > 0,
                'Checkbox--dark': dark,
                'Checkbox--gray': gray,
                'Checkbox--light': !(dark || gray),
            })}
            onClick={disabled ? () => {} : () => onChange(id, !value)}
        >
            {value && <Icon icon={value ? 'checkmark' : ''} size="16px" />}
            <input id={id} value={value} type="checkbox" hidden />
        </div>
    );
}

Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.any /* eslint react/forbid-prop-types: 0 */,
    disabled: PropTypes.bool,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.shape({})),
    onChange: PropTypes.func,
    tabIndex: PropTypes.number,
    error: PropTypes.bool,
};

Checkbox.defaultProps = {
    value: false,
    disabled: false,
    dark: false,
    gray: false,
    errors: [],
    onChange: () => {},
    tabIndex: 0,
    error: false,
};
