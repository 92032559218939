import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import ListItem from '../../ListItem';

require('./styles.scss');

function DragAndDropListItem(props) {
    const { id, canDrag, label, type, primary, onClick, decorations } = props;

    // add drag interaction
    const [{ isDragging }, drag] = useDrag({
        type: 'dragAndDropListItem',
        item: { ...props },
        canDrag: () => canDrag,
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    return (
        <div
            className={ClassNames('DragAndDropListItem', {
                'DragAndDropListItem--canDrag': canDrag,
                'DragAndDropListItem--isDragging': isDragging,
            })}
            ref={drag}
        >
            <ListItem id={id} active={primary} onClick={onClick} type={type} decorations={decorations} name={label} />
        </div>
    );
}
DragAndDropListItem.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.node,
    primary: PropTypes.bool,
    canDrag: PropTypes.bool,
    onClick: PropTypes.func,
    decorations: PropTypes.node,
};

DragAndDropListItem.defaultProps = {
    primary: false,
    type: null,
    canDrag: true,
    onClick: () => {},
    decorations: null,
};

export default DragAndDropListItem;
