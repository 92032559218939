import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Status from './Status';

require('./styles.scss');

export default class BigKPI extends PureComponent {
    render() {
        const { label, value, unit, statusIcon, statusLabel, statusColour } = this.props;
        return (
            <div className={ClassNames('KPI', 'KPI--big')}>
                <div className="KPI__label">{label}</div>
                <div className="KPI__row">
                    <div className="KPI__column">
                        <div className="KPI__value">{value}</div>
                    </div>
                    <div className="KPI__column KPI__column--right">
                        <Status label={statusLabel} icon={statusIcon} colour={statusColour} />
                        <div className="KPI__unit">{unit}</div>
                    </div>
                </div>
            </div>
        );
    }
}

BigKPI.propTypes = {
    label: PropTypes.string,
    value: PropTypes.node,
    unit: PropTypes.string,
    statusIcon: PropTypes.string,
    statusLabel: PropTypes.string,
    statusColour: PropTypes.string,
};

BigKPI.defaultProps = {
    label: '',
    value: '',
    unit: '',
    statusIcon: null,
    statusLabel: null,
    statusColour: null,
};
