import { createActions, createReducer } from 'reduxsauce';

const { Creators, Types } = createActions(
    {
        showRequest: ['query'],
        showSuccess: ['permission'],
        showFailure: ['errors'],
    },
    { prefix: 'PUBLIC_PAGES_' },
);

export default createReducer({}, {});

export { Creators as Actions, Types };
