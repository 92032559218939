import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import lodash from 'lodash';

import ContextMenu from '../../ContextMenu';
import TextInput from '../Text';
import SelectInput from '../Select';
import SelectButton from '../SelectButtons';

const hourOptions = lodash.range(24).map((h) => ({ label: h.toString(), value: h.toString() }));
const minuteOptions = lodash.range(60).map((m) => {
    const minuteText = m > 9 ? m.toString() : `0${m}`;
    return { label: minuteText, value: minuteText };
});
const amOptions = [
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' },
];

const timeStringMoment = (timeString) => moment.utc(`1970-01-01 ${timeString}`, 'YYYY-DD-MM h:mm a');

require('./styles.scss');

// takes a string of the form "h:mm a" as a value
export default function TimeInput(props) {
    const { id, value, onChange, placeholder, icon, error, disabled, gray, dark, rounded, sm } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const [typedValue, setTypedValue] = useState(null); // value typed into the text input
    const valueMoment = value ? timeStringMoment(value) : null;
    const hour = valueMoment ? valueMoment.format('h') : '0';
    const minute = valueMoment ? valueMoment.format('mm') : '00';
    const amString = valueMoment?.hour() > 11 ? 'PM' : 'AM';
    return (
        <div className="TimeInput">
            <div
                role="button"
                tabIndex={0}
                className="TimeInput__label"
                onClick={() => {
                    if (!disabled) setMenuOpen(!menuOpen);
                }}
            >
                <TextInput
                    id={id}
                    sm={sm}
                    onChange={(_, val) => {
                        setTypedValue(val);
                    }}
                    onBlur={(_, val) => {
                        if (val && timeStringMoment(val).isValid()) {
                            onChange(id, val);
                            setTypedValue(null);
                        }
                    }}
                    value={typedValue || value}
                    placeholder={placeholder}
                    icon={icon}
                    disabled={disabled}
                    gray={gray}
                    dark={dark}
                    rounded={rounded}
                    error={error}
                    size={12}
                />
            </div>
            <ContextMenu className="TimeInput__menu" open={menuOpen} onClickOut={() => setMenuOpen(false)}>
                <div className="TimeInput__menuContent">
                    <SelectInput
                        id="hour"
                        value={hour}
                        onChange={(_, val) => onChange(id, `${val}:${minute} ${amString}`)}
                        options={hourOptions}
                        dark
                    />
                    <SelectInput
                        id="minute"
                        value={minute}
                        onChange={(_, val) => onChange(id, `${hour}:${val} ${amString}`)}
                        options={minuteOptions}
                        dark
                    />
                    <SelectButton
                        id="am"
                        value={amString}
                        onChange={(_, val) => onChange(id, `${hour}:${minute} ${val}`)}
                        options={amOptions}
                        dark
                    />
                </div>
            </ContextMenu>
        </div>
    );
}

TimeInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    gray: PropTypes.bool,
    dark: PropTypes.bool,
    rounded: PropTypes.bool,
    sm: PropTypes.bool,
    // TODO: max & min props
};

TimeInput.defaultProps = {
    error: false,
    icon: 'clock',
    placeholder: '',
    value: '',
    disabled: false,
    gray: false,
    dark: false,
    rounded: false,
    sm: false,
};
