import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BigKpi from './big';
import SmallKpi from './small';

require('./styles.scss');

export default class KPI extends PureComponent {
    render = () => {
        const { big } = this.props;
        return big ? <BigKpi {...this.props} /> : <SmallKpi {...this.props} />;
    };
}

KPI.propTypes = {
    big: PropTypes.bool,
};

KPI.defaultProps = {
    big: false,
};
