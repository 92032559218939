import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { renderComponentAppended } from '../../../helpers/component';

require('./styles.scss');

export default class Modal extends PureComponent {
    render() {
        const { open, onClose, children } = this.props;
        return open
            ? renderComponentAppended(
                  <div className="Modal" onClick={(e) => e.stopPropagation()} role="none">
                      <div
                          role="button"
                          className="Modal__overlay"
                          onClick={onClose}
                          tabIndex={0}
                          label="Outside modal"
                      />
                      <div className="Modal__content">{children}</div>
                  </div>,
                  'modal-root',
              )
            : null;
    }
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
    open: false,
};
