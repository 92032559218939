import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Loader from '../../Loaders';
import CheckBox from '../Checkbox';
import SearchInput from '../Search';
import ListItem from '../../ListItem';
import ScrollArea from '../../ScrollArea';
import { applyFilter, isAllSelected } from './helpers';

require('./styles.scss');

export default class ListInput extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { filter: '' };
    }

    handleItemClick = (id) => {
        const { onItemClick } = this.props;
        if (onItemClick) {
            onItemClick(id);
        } else {
            this.handleChange(id);
        }
    };
    handleCheckboxChange = (id) => {
        const { onItemClick } = this.props;
        if (onItemClick) {
            this.handleChange(id);
        }
    };
    handleChange = (valueKey) => {
        const { value, multiSelect, onChange, id } = this.props;
        if (multiSelect) {
            const valueFromProps = { ...value };
            if (valueFromProps[valueKey]) delete valueFromProps[valueKey];
            else valueFromProps[valueKey] = true;
            onChange(id, valueFromProps);
        } else {
            onChange(id, valueKey);
        }
    };
    handleSelectAll = (items) => {
        const { value, onChange, id } = this.props;
        const valueFromProps = { ...value };
        if (isAllSelected(items, value)) {
            items.forEach((item) => delete valueFromProps[item.value || item.label || item]);
            onChange(id, valueFromProps);
        } else {
            items.forEach((item) => {
                valueFromProps[item.value || item.label || item] = true;
            });
            onChange(id, valueFromProps);
        }
    };
    changeFilter = (id, value) => {
        this.setState({ filter: value }, () => {
            if (this.scrollRef) this.scrollRef.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        });
    };
    setScrollRef = (el) => {
        this.scrollRef = el;
    };
    render() {
        const {
            value,
            dark,
            gray,
            light,
            border,
            sm,
            multiSelect,
            emptyState,
            loading,
            loadingNumber,
            autoFocus,
            header,
            items,
        } = this.props;

        const { filter } = this.state;
        const filteredItems = applyFilter(items, filter);

        const listItems =
            !loading && filteredItems.length === 0 ? (
                <div className="ListInput__empty">{emptyState}</div>
            ) : (
                filteredItems.map((item) => (
                    <ListItem
                        id={item.value || item.label || item}
                        key={item.value || item.label || item}
                        active={
                            item.active ||
                            (!multiSelect && (item.value === value || item === value || item.label === value))
                        }
                        dark={dark}
                        sm={sm}
                        onClick={this.handleItemClick}
                        name={item.label || item.value || item}
                        decorations={item.decorations}
                        type={
                            multiSelect && (
                                <CheckBox
                                    id={item.value || item.label || item}
                                    dark={dark}
                                    value={value[item.value || item.label || item]}
                                    tabIndex={-1}
                                    onChange={this.handleCheckboxChange}
                                />
                            )
                        }
                        style={item.style}
                    />
                ))
            );
        return (
            <div
                className={ClassNames('ListInput', {
                    'ListInput--dark': dark,
                    'ListInput--gray': gray,
                    'ListInput--light': light,
                    'ListInput--header': header,
                    'ListInput--noBorder': !border,
                })}
            >
                {header && (
                    <header className="ListInput__header">
                        {multiSelect && (
                            <CheckBox
                                id="selectAll"
                                type="checkbox"
                                dark={dark}
                                onChange={() => this.handleSelectAll(filteredItems)}
                                value={isAllSelected(filteredItems, value)}
                            />
                        )}
                        <SearchInput
                            id="filter"
                            type="search"
                            placeholder="Search"
                            dark={dark}
                            gray={gray}
                            light={light}
                            value={filter}
                            onChange={this.changeFilter}
                            onSubmit={() => this.handleSelectAll(filteredItems)}
                            autoFocus={autoFocus}
                        />
                    </header>
                )}
                <ScrollArea className="ListInput__List" dark={dark} setRef={this.setScrollRef}>
                    {loading
                        ? Array.from({ length: loadingNumber }, (_, i) => (
                              <div key={`loader-${i}`} className="ListInput--loaderRow">
                                  <Loader type="content" loader="content" dark={dark} />
                              </div>
                          ))
                        : listItems}
                </ScrollArea>
            </div>
        );
    }
}
ListInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onItemClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    multiSelect: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
                decorations: PropTypes.node,
                active: PropTypes.bool,
            }),
        ]),
    ),
    emptyState: PropTypes.node,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    light: PropTypes.bool,
    border: PropTypes.bool,
    sm: PropTypes.bool,
    autoFocus: PropTypes.bool,
    loading: PropTypes.bool,
    loadingNumber: PropTypes.number,
    header: PropTypes.bool,
};

ListInput.defaultProps = {
    onChange: () => {},
    onItemClick: undefined,
    items: [],
    value: {},
    multiSelect: false,
    emptyState: 'No items',
    dark: false,
    gray: false,
    light: false,
    border: true,
    sm: false,
    autoFocus: false,
    loading: false,
    loadingNumber: 5,
    header: true,
};
