import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

const RadioOption = (props) => {
    const { selected, id, onSelect, option, error, dark, disabled: inputDisabled } = props;

    const optionValue = option.value === undefined ? option.label : option.value;
    const disabled = option.disabled || inputDisabled;

    return (
        <div
            className={ClassNames('RadioOption', {
                'RadioOption--error': error,
                'RadioOption--dark': dark,
                'RadioOption--disabled': disabled,
            })}
        >
            <label htmlFor={`${id}_${optionValue}`}>
                <input
                    id={`${id}_${optionValue}`}
                    type="radio"
                    value={optionValue}
                    name={`RadioOption_${id}`}
                    onChange={disabled ? () => {} : (event) => onSelect(event)}
                    checked={selected}
                />
                <div className="RadioOption__radioButton" />
                <span className="RadioOption__label">{option.label || option.value}</span>
            </label>
        </div>
    );
};

RadioOption.propTypes = {
    id: PropTypes.string.isRequired,
    option: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        value: PropTypes.any,
        label: PropTypes.string,
        disabled: PropTypes.bool,
    }).isRequired,
    selected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    error: PropTypes.bool,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
};

RadioOption.defaultProps = {
    selected: false,
    error: false,
    dark: false,
    disabled: false,
};

export default RadioOption;
