/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import { createOptionsArray } from '../helpers';

import styles from './styles.emotion';

export default class SelectInput extends Component {
    handleChange = (selectedObj) => {
        const { onChange, id } = this.props;
        if (selectedObj) {
            onChange(id, selectedObj.id ?? selectedObj.value ?? selectedObj.label);
        } else {
            onChange(id, '');
        }
    };
    handleBlur = (e) => {
        const { onBlur, submitOnBlur, onSubmit, id } = this.props;
        onBlur(e);
        if (submitOnBlur) {
            onSubmit(id, e.target.value);
        }
    };
    render() {
        const { disabled, options: optionsProp, value, onSubmit, id } = this.props;
        const options = createOptionsArray(optionsProp);
        const typeSafeValue = options && options.find((option) => option.value === value);
        const mobileProps = isMobile
            ? {
                  isSearchable: false,
                  // these 2 are recommended by react-select in their docs
                  blurInputOnSelect: true,
                  captureMenuScroll: false,
              }
            : {};

        return (
            <Select
                menuPortalTarget={document.getElementById('context-menu-root')}
                {...this.props}
                {...mobileProps}
                isDisabled={disabled}
                options={options || []}
                styles={styles(this.props)}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onSubmit={onSubmit}
                value={typeSafeValue || null}
                loading={!value}
                name={id}
                menuPlacement="auto"
            />
        );
    }
}
SelectInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.any,
            }),
        ),
    ]),
    value: PropTypes.any,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    submitOnBlur: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onSubmit: PropTypes.func,
};
SelectInput.defaultProps = {
    value: '',
    dark: false,
    gray: false,
    submitOnBlur: false,
    disabled: false,
    options: [],
    onSubmit: () => {},
    onBlur: () => {},
};
