import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, TextButton } from '../../shared';
import ElementDisplay from '../Elements/Display';

import TermsModal from './termsModal';
import PrivacyModal from './privacyModal';

import './styles.scss';

const getValidation = (element) => {
    switch (element.type) {
        case 'number':
            if (element.config.allowDecimal === false) {
                return {
                    validate: (values) =>
                        values[element._id] === parseInt(values[element._id]) || values[element._id] === null,
                    message: 'Please enter a whole number.',
                };
            }
            return null;
        case 'checkbox':
            if (element.config.required === true) {
                return {
                    validate: (values) => values[element._id] === null || values[element._id].some((val) => val),
                    message: 'Please choose one or more.',
                };
            }
            return null;
        default:
            return null;
    }
};

export default function FormDisplay({ form, onSubmit, showTerms }) {
    const [termsOpen, setTermsOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);

    if (!form?.elements?.length) return <div className="FormDisplay" />;

    return (
        <Form
            className="FormDisplay"
            inputListeners={[ElementDisplay]}
            errorElementSelector=".QuestionElement--error"
            onSubmit={onSubmit}
        >
            {form.elements.map((element, i) => (
                <ElementDisplay
                    key={element._id}
                    element={element}
                    animationDelay={i * 100}
                    id={element._id}
                    value={null}
                    required={element.config?.required}
                    validation={getValidation(element)}
                />
            ))}
            {showTerms && (
                <div className="FormDisplay__terms">
                    By submitting this form, you are agreeing to our{' '}
                    <TextButton onClick={() => setTermsOpen(true)}>terms and conditions</TextButton>
                    {' and '}
                    <TextButton onClick={() => setPrivacyOpen(true)}>privacy policy</TextButton>.
                </div>
            )}
            {onSubmit && (
                <Button id="submit" blue>
                    Submit
                </Button>
            )}
            <TermsModal open={termsOpen} onClose={() => setTermsOpen(false)} />
            <PrivacyModal open={privacyOpen} onClose={() => setPrivacyOpen(false)} />
        </Form>
    );
}

FormDisplay.propTypes = {
    form: PropTypes.shape({
        elements: PropTypes.array,
    }).isRequired,
    onSubmit: PropTypes.func,
    showTerms: PropTypes.bool,
};

FormDisplay.defaultProps = {
    onSubmit: null,
    showTerms: false,
};
