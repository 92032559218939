import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Circle } from 'rc-progress';

import { neutral95, red400, green500 } from '../../../../styles/partials/colors';
import Icon from '../../Icon';
import KPI from '../../KPI';
import TextButton from '../../TextButton';
import { statusToIcon } from './constants';

require('./styles.scss');

export default function Job({ name, status, timeCreated, lastUpdated, onAction, expanded, meta, onClick }) {
    return (
        <div className={`Job Job--${status}`} onClick={onClick} role="menuitem" tabIndex={-1}>
            <header>
                <div className="Job__icon">
                    {['continue', 'failed', 'completed'].includes(status) ? (
                        <Icon icon={statusToIcon[status] || 'exclamation-triangle'} size="20px" />
                    ) : (
                        <Circle
                            percent={meta?.progress || 0}
                            strokeWidth="15"
                            trailWidth="15"
                            trailColor={neutral95}
                            strokeColor={status === 'failed' ? red400 : green500}
                        />
                    )}
                </div>
                <div className="Job__name">{name}</div>
                <div className="Job__status">
                    {onAction ? (
                        <TextButton blue onClick={onAction}>
                            <b>{status}</b>
                        </TextButton>
                    ) : (
                        status
                    )}
                </div>
            </header>
            {expanded && (
                <div className="Job__KPIs">
                    {meta && meta.error ? (
                        <KPI
                            horizontal
                            label="Error"
                            value={
                                meta.error.slice(0, 6) === 'Error:'
                                    ? meta.error.substring(6, meta.error.length)
                                    : meta.error
                            }
                        />
                    ) : (
                        <KPI horizontal label="Status" value={status} />
                    )}
                    <KPI
                        horizontal
                        label="Start"
                        value={moment.utc(timeCreated).local().format('YYYY-MM-DD, hh:mm:ss A')}
                    />
                    <KPI
                        horizontal
                        label="End"
                        value={moment.utc(lastUpdated).local().format('YYYY-MM-DD, hh:mm:ss A')}
                    />
                </div>
            )}
        </div>
    );
}

Job.propTypes = {
    name: PropTypes.string,
    status: PropTypes.string,
    // type: PropTypes.string,
    timeCreated: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    lastUpdated: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onAction: PropTypes.func,
    meta: PropTypes.shape({ progress: PropTypes.number, error: PropTypes.string }),
    expanded: PropTypes.bool,
    onClick: PropTypes.func,
};

Job.defaultProps = {
    name: '',
    status: '',
    // type: '',
    timeCreated: '',
    lastUpdated: '',
    meta: null,
    onAction: null,
    expanded: false,
    onClick: () => {},
};
