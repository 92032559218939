/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ClassNames from 'classnames';
import Card from '../../Card';
import Modal from '..';
import IconButton from '../../IconButton';
import Button from '../../Button';
import Form from '../../Form';

require('./styles.scss');

export default class ModalCard extends Component {
    componentDidMount = () => {
        const { open } = this.props;
        if (open) this.autofocus();
    };
    componentDidUpdate = (prevProps) => {
        const { open } = this.props;
        if (prevProps.open === false && open === true) {
            this.autofocus();
        }
    };
    handleBlur = () => {
        // if somebody hits <Tab> at the bottom of the modal, return focus to top of modal.
        if (this.headerRef) {
            this.autofocus();
        }
    };
    setHeaderRef = (el) => {
        this.headerRef = el;
    };
    autofocus = () => {
        const { autofocus } = this.props;
        const el = document.querySelector('.ModalCard input');
        if (el && autofocus) {
            el.focus();
        } else if (this.headerRef) {
            this.headerRef.focus();
        }
    };

    render() {
        const {
            title,
            onClose,
            children,
            open,
            subtitle,
            className,
            primaryButtonOptions,
            secondaryButtonOptions,
            redButtonOptions,
            formOptions,
            footer,
        } = this.props;

        const primaryButton = primaryButtonOptions ? (
            <Button blue {...primaryButtonOptions}>
                {primaryButtonOptions.content}
            </Button>
        ) : null;
        const secondaryButton = secondaryButtonOptions ? (
            <Button gray {...secondaryButtonOptions}>
                {secondaryButtonOptions.content}
            </Button>
        ) : null;
        const redButton = redButtonOptions ? (
            <Button red {...redButtonOptions}>
                {redButtonOptions.content}
            </Button>
        ) : null;

        const modalCardFooter = footer || (
            <div className="ModalCard__footer">
                {secondaryButton}
                {primaryButton}
                {redButton}
            </div>
        );

        // wrap with a form if formOptions are specified
        const content = formOptions ? (
            <Form {...formOptions}>
                {children}
                {modalCardFooter}
            </Form>
        ) : (
            <div>
                {children}
                {modalCardFooter}
            </div>
        );

        const modalCard = (
            <div
                className={ClassNames('ModalCard', className)}
                onKeyDown={(e) => {
                    if (e.key === 'Escape' && open) onClose();
                }}
            >
                <Card>
                    <div className="ModalCard__header" tabIndex={-1} ref={this.setHeaderRef} role="none">
                        {(title || subtitle) && (
                            <div className="ModalCard__titles">
                                <div className="ModalCard__title">{title}</div>
                                {subtitle && <div className="ModalCard__subtitle">{subtitle}</div>}
                            </div>
                        )}
                    </div>
                    {content}
                    <IconButton
                        icon="times"
                        mini
                        redAlert
                        onClick={onClose}
                        className="ModalCard__close"
                        onBlur={this.handleBlur}
                    />
                    <div tabIndex={0} /> {/* This div is here to make the focus trap work */}
                </Card>
            </div>
        );
        return (
            <Modal open={open} onClose={onClose}>
                {modalCard}
            </Modal>
        );
    }
}

ModalCard.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.node,
    subtitle: PropTypes.string,
    className: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.node,
    primaryButtonOptions: PropTypes.shape({
        onClick: PropTypes.func,
        content: PropTypes.node,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        id: PropTypes.string,
    }),
    secondaryButtonOptions: PropTypes.shape({
        onClick: PropTypes.func,
        content: PropTypes.node,
        disabled: PropTypes.bool,
    }),
    redButtonOptions: PropTypes.shape({
        onClick: PropTypes.func,
        content: PropTypes.node,
        disabled: PropTypes.bool,
    }),
    formOptions: PropTypes.shape({
        onSubmit: PropTypes.func,
    }),
    autofocus: PropTypes.bool,
    footer: PropTypes.node,
};

ModalCard.defaultProps = {
    open: false,
    onClose: () => {},
    title: '',
    subtitle: '',
    className: null,
    children: null,
    primaryButtonOptions: null,
    secondaryButtonOptions: null,
    redButtonOptions: null,
    formOptions: null,
    autofocus: false,
    footer: null,
};
