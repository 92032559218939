import whale404 from '../../../../assets/whale404.svg';
import whale500 from '../../../../assets/whale500.svg';
import whale502 from '../../../../assets/whale502.svg';

export const errorPages = {
    notFound: {
        exclamation: 'Page Not Found',
        message: "Whale I can't find the page you're looking for.",
        whale: whale404,
    },
    maintenance: {
        exclamation: 'Maintenance Underway',
        message: "You'll usually SEA this error if our team is upgrading the system. Check back soon.",
        whale: whale502,
    },
    badRequest: {
        exclamation: 'Something Went Whalely Wrong',
        message: 'Our team will be looking into this.',
        whale: whale500,
    },
    default: {
        exclamation: 'Something Went Whalely Wrong',
        message: 'Our team will be looking into this.',
        whale: whale500,
    },
};
