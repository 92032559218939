import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Icon from '../Icon';
import InfoToolTip from '../InfoToolTip';
import { inputFields } from './constants';

require('./styles.scss');

export default class Input extends PureComponent {
    handleSubmit = () => {
        const { onSubmit, id, value } = this.props;
        onSubmit(id, value);
    };
    handleBlur = () => {
        const { onSubmit, id, value, onBlur, submitOnBlur } = this.props;
        onBlur(id, value);
        if (submitOnBlur) {
            onSubmit(id, value);
        }
    };
    handleKeyPress = (e) => {
        const { onSubmit, id, value, onKeyPress } = this.props;
        if (e.key === 'Enter') {
            e.preventDefault();
            onKeyPress(e);
            onSubmit(id, value);
        }
    };

    render() {
        const {
            id,
            className,
            label,
            type,
            errors,
            disabled,
            dark,
            gray,
            hidden,
            inlineLabel,
            labelRight,
            info,
            required,
        } = this.props;
        const hasError = errors && errors.length > 0 && !(type === 'file');
        const InputField = inputFields[type];
        return (
            <div
                className={ClassNames(`Input ${className}`, {
                    'Input--error': hasError,
                    'Input--disabled': disabled,
                    'Input--hidden': hidden,
                    'Input--light': !(dark || gray),
                    'Input--dark': dark,
                    'Input--gray': gray,
                })}
            >
                <div
                    className={ClassNames('Input__container', {
                        'Input__container--inlineLabel': inlineLabel,
                        'Input__container--labelRight': labelRight,
                    })}
                >
                    {label ? (
                        <label
                            htmlFor={id}
                            className={ClassNames('Input__label', {
                                'Input__label--responsive':
                                    type !== 'checkbox' && type !== 'radio' && type !== 'switch',
                            })}
                        >
                            {label}
                            {required && !disabled && <span className="Input__required">*</span>}
                        </label>
                    ) : null}
                    <InputField
                        {...this.props}
                        onSubmit={this.handleSubmit}
                        onKeyPress={this.handleKeyPress}
                        onBlur={this.handleBlur}
                        error={hasError}
                    />
                    {info && (
                        <div className="Input__info">
                            <InfoToolTip size="16px" place="right">
                                {info}
                            </InfoToolTip>
                        </div>
                    )}
                </div>
                {hasError && (
                    <div className="Input__error" key={errors[0].message}>
                        {errors[0].message}
                        <Icon icon="exclamation-triangle" size="12px" />
                    </div>
                )}
            </div>
        );
    }
}

Input.propTypes = {
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onSubmit: PropTypes.func,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    labelRight: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
    hidden: PropTypes.bool,
    submitOnBlur: PropTypes.bool,
    info: PropTypes.string,
    maxWidth: PropTypes.number,
};

Input.defaultProps = {
    label: null,
    value: '',
    onChange: () => {},
    onClick: () => {},
    onSubmit: () => {},
    onKeyPress: () => {},
    onBlur: () => {},
    required: false,
    className: '',
    disabled: false,
    dark: false,
    gray: false,
    inlineLabel: false,
    labelRight: false,
    errors: [],
    hidden: false,
    submitOnBlur: false,
    info: null,
    maxWidth: null,
};
