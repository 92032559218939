import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { recursivelyModifyChildren, recursivelyReduceChildren, childOfType } from '../../../helpers/component';
import Input from '../Input';

require('./styles.scss');

const isInput = (child, inputListeners) => inputListeners.some((type) => childOfType(child, type));
const valueExists = (val) => !!val || val === 0 || val === false;

export default class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {}, // the values of the inputs managed by the form, by id
            errors: [], // an array of errors of the form { id: inputId, message: "error message" }
        };
    }

    static getDerivedStateFromProps = (props, state) => {
        const { values } = state;
        if (props.loading) return null;
        const nextValues = recursivelyReduceChildren(
            props.children,
            (acc, child) => {
                // go through all children recursively,
                // check whether the child is an Input with an id that hasn't been registered with the Form yet
                if (
                    isInput(child, props.inputListeners) &&
                    child.props.id &&
                    !child.props.loading &&
                    values[child.props.id] === undefined
                ) {
                    return { ...acc, [child.props.id]: child.props.value };
                }
                return acc;
            },
            null,
        );
        return nextValues && { values: nextValues };
    };

    validate = (props, state) => {
        const { children, inputListeners } = props || this.props;
        const { values } = state || this.state;
        return recursivelyReduceChildren(
            children,
            (acc, child) => {
                if (isInput(child, inputListeners)) {
                    if (child.props.required && !valueExists(values[child.props.id]))
                        acc.push({ id: child.props.id, message: 'This input is required.' });
                    if (child.props.validation && !child.props.validation.validate(values)) {
                        acc.push({ id: child.props.id, message: child.props.validation.message });
                    }
                    if (child.props.validations) {
                        child.props.validations.forEach((validation) => {
                            if (!validation.validate(values))
                                acc.push({ id: child.props.id, message: validation.message });
                        });
                    }
                }
                return acc;
            },
            [],
        );
    };

    handleChange = (id, value) => {
        const { values, errors } = this.state;
        const nextValues = { ...values, [id]: value };

        const otherErrors = errors.filter((error) => error.id !== id);
        const inputErrors = this.validate(undefined, { values: nextValues }).filter(
            // we only remove errors onChange.  Errors can be added on blur or on submit
            (error) => error.id === id && errors.find((err) => err.id === error.id && error.message === err.message),
        );
        this.setState({ values: nextValues, errors: [...otherErrors, ...inputErrors] });
    };

    handleBlur = (id) => {
        const { errors } = this.state;
        const inputErrors = this.validate().filter((error) => error.id === id);
        const nextErrors = errors.filter((error) => error.id !== id).concat(inputErrors);
        this.setState({ errors: nextErrors });
    };

    handleSubmit = () => {
        const { errorElementSelector, onSubmit } = this.props;
        const { values } = this.state;
        const errors = this.validate();
        if (errors.length === 0) {
            onSubmit(values);
        } else {
            this.setState({ errors }, () => {
                this.formRef.querySelector(errorElementSelector)?.scrollIntoView({ behavior: 'smooth' });
            });
        }
    };

    setFormRef = (el) => {
        this.formRef = el;
    };

    render() {
        const { children, className, loading, onSubmit } = this.props;
        const { values, errors } = this.state;

        const modifiedChildren = recursivelyModifyChildren(children, (child) => {
            // modify the props of the children before we render them
            const childProps = {};
            if (child.props.id in values) {
                childProps.onChange = this.handleChange;
                childProps.onBlur = this.handleBlur;
                if (onSubmit) childProps.onSubmit = this.handleSubmit; // conditionally modifying onSubmit for weird use cases (eg. image KPI)
                childProps.loading = loading || childProps.loading;
                childProps.value = values[child.props.id];
                childProps.errors = errors.filter((error) => error.id === child.props.id);
                if (child.props.errors) childProps.errors = [...child.props.errors, ...childProps.errors];
            } else if (child.props.id === 'submit') {
                childProps.onClick = this.handleSubmit;
                if (onSubmit) childProps.onSubmit = this.handleSubmit;
            }
            return childProps;
        });

        return (
            <form className={ClassNames('Form', className)} ref={this.setFormRef}>
                {modifiedChildren}
            </form>
        );
    }
}

Form.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    inputListeners: PropTypes.array,
    errorElementSelector: PropTypes.string,
};

Form.defaultProps = {
    className: null,
    onSubmit: null,
    loading: false,
    inputListeners: [Input],
    errorElementSelector: '.Input--error',
};
