import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ContextMenu from '../../ContextMenu';
import ContextSubMenu from '../../ContextMenu/ContextSubMenu';
import ContextMenuItem from '../../ContextMenu/ContextMenuItem';

import ListInput from '../List';
import SearchInput from '../Search';
import { applyFilter } from '../List/helpers';
import { createOptionsArray } from '../helpers';
import MultiSelectInput from './input';

require('./styles.scss');

export default function MultiSelect(props) {
    const { id, onChange, options: optionsProp, multiSelectHeading, value } = props;
    const options = createOptionsArray(optionsProp);
    const [menuOpen, setMenuOpen] = useState(false);
    const [filterString, setFilterString] = useState('');
    let filteredOptions = [];
    if (options?.length) {
        filteredOptions = applyFilter(options, filterString);
    }
    const listInputValue = {};
    if (value && value.length) {
        value.forEach((itm) => {
            listInputValue[itm] = true;
        });
    }
    const toggleMenu = () => setMenuOpen(!menuOpen);

    return (
        <MultiSelectInput
            {...props}
            options={options}
            toggleMenu={toggleMenu}
            menuOpen={menuOpen}
            contextMenu={
                <ContextMenu
                    className="MultiSelectInput__contextMenu"
                    open={menuOpen}
                    onClickOut={toggleMenu}
                    listHeading={multiSelectHeading}
                >
                    <ContextSubMenu>
                        <header className="MultiSelectInput__header">
                            <SearchInput
                                id="multiSelectSearch"
                                type="search"
                                editable
                                dark
                                placeholder="Filter"
                                onChange={(_, filterValue) => setFilterString(filterValue)}
                                value={filterString}
                                sm
                            />
                        </header>
                        <ListInput
                            id="multiSelectList"
                            type="list"
                            dark
                            multiSelect
                            sm
                            header={false}
                            items={filteredOptions}
                            border={false}
                            value={listInputValue}
                            onChange={(_id, val) => {
                                let selectedItems = Object.keys(val);
                                if (!selectedItems.length) selectedItems = null;
                                onChange(id, selectedItems);
                                return selectedItems;
                            }}
                        />
                    </ContextSubMenu>
                    <ContextSubMenu>
                        <ContextMenuItem
                            id="selectAll"
                            name="Select All"
                            onClick={() => {
                                const newSelectedItems = filteredOptions.map((item) => item.value);
                                const allSelectedItems = [...new Set([...(value || []), ...newSelectedItems])];
                                onChange(id, allSelectedItems);
                            }}
                            value={listInputValue}
                        />
                        <ContextMenuItem
                            id="clearAll"
                            name="Clear All"
                            onClick={() => {
                                const nextValue = value
                                    ? value.filter((val) => !filteredOptions.find((item) => item.value === val))
                                    : [];
                                onChange(id, nextValue);
                            }}
                            value={listInputValue}
                        />
                    </ContextSubMenu>
                </ContextMenu>
            }
        />
    );
}

MultiSelect.propTypes = {
    id: PropTypes.string.isRequired,
    multiSelectHeading: PropTypes.string,
    onChange: PropTypes.func,
    onOptionClick: PropTypes.func,
    options: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            }),
        ),
    ]),
    value: PropTypes.arrayOf(PropTypes.string),
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    light: PropTypes.bool,
    border: PropTypes.bool,
    infinite: PropTypes.bool,
    sm: PropTypes.bool,
    emptyState: PropTypes.node,
    loading: PropTypes.bool,
    horizontal: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
};

MultiSelect.defaultProps = {
    onChange: () => {},
    onOptionClick: undefined,
    multiSelectHeading: '',
    value: [],
    dark: false,
    gray: false,
    light: false,
    border: true,
    infinite: true,
    options: [],
    sm: false,
    emptyState: 'No items',
    loading: false,
    horizontal: false,
    disabled: false,
    placeholder: null,
};
