import { Actions } from './reducers';
import FormsApi from './api';
import { operations } from '../../../models/base';

const defaultElement = { type: 'shortText', config: {} };

const createElement = (formId, element = defaultElement, index = null) => (dispatch) => {
    dispatch(Actions.createElementRequest(formId, element, index));
    return FormsApi.createElement(formId, element, index).then(
        (form) => dispatch(Actions.createElementSuccess(formId, form)),
        (error) => dispatch(Actions.createElementFailure(formId, error)),
    );
};
const updateElement = (id, elementId, element) => (dispatch) => {
    dispatch(Actions.updateElementRequest(id, elementId, element));
    return FormsApi.updateElement(id, elementId, element).then(
        (form) => dispatch(Actions.updateElementSuccess(id, elementId, form)),
        (error) => dispatch(Actions.updateElementFailure(id, elementId, error)),
    );
};

const deleteElement = (id, elementId) => (dispatch) => {
    dispatch(Actions.deleteElementRequest(id, elementId));
    return FormsApi.deleteElement(id, elementId).then(
        (form) => dispatch(Actions.deleteElementSuccess(id, elementId, form)),
        (error) => dispatch(Actions.deleteElementFailure(id, elementId, error)),
    );
};

const duplicate = (formId) => (dispatch) => {
    dispatch(Actions.duplicateRequest(formId));
    return FormsApi.duplicate(formId).then(
        (form) => dispatch(Actions.duplicateSuccess(formId, form)),
        (error) => dispatch(Actions.duplicateFailure(formId, error)),
    );
};

const publish = (formId) => (dispatch) => {
    dispatch(Actions.publishRequest(formId));
    return FormsApi.publish(formId).then(
        (form) => dispatch(Actions.publishSuccess(formId, form)),
        (error) => dispatch(Actions.publishFailure(formId, error)),
    );
};

const submit = (formId, submission) => (dispatch) => {
    dispatch(Actions.submitRequest(formId, submission));
    return FormsApi.submit(formId, submission).then(
        (response) => dispatch(Actions.submitSuccess(formId, response)),
        (error) => dispatch(Actions.submitFailure(formId, error)),
    );
};

export default operations(Actions, FormsApi, {
    createElement,
    deleteElement,
    updateElement,
    duplicate,
    publish,
    submit,
});
