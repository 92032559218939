const handlers = [];

export function getRootClickHandler() {
    return (e) => handlers.forEach((handler) => handler(e));
}

export function getHierarchicalClickHandler(handler) {
    const index = handlers.findIndex((h) => h === handler);
    return handlers.slice(index + 1);
}

export function removeRootClickHandler(handler) {
    const index = handlers.findIndex((h) => h === handler);
    handlers.splice(index, 1);
}

export function addRootClickHandler(handler) {
    handlers.push(handler);
    return () => removeRootClickHandler(handler);
}
