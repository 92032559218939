import React from 'react';
import PropTypes from 'prop-types';
import { BarLoader, MoonLoader, ScaleLoader } from 'react-spinners';
import { blue500 } from '../../../styles/partials/colors';

require('./styles.scss');

const loaders = {
    scale: ScaleLoader,
    moon: MoonLoader,
    bar: BarLoader,
};

const Spinner = (props) => {
    const { loader } = props;
    const LoadingSpinner = loaders[loader];
    return <LoadingSpinner {...props} />;
};

Spinner.propTypes = {
    loader: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
    height: PropTypes.number,
};

Spinner.defaultProps = {
    loader: 'scale',
    color: blue500,
    size: 32,
    height: 32,
};

export default Spinner;
