import ApiBase from '../../../models/base/api';
import { createQueryString } from '../../../models/base/api/helpers';

export class FormApi extends ApiBase {
    constructor() {
        const addProjectIdToSearchParams = true;
        super('forms', addProjectIdToSearchParams);
    }

    createElement = (formId, element, index) => {
        const queryString = createQueryString({ index });
        return this.request('POST', `${this.endpoint}/${formId}/elements${queryString}`, element);
    };

    deleteElement = (formId, elementId) => this.request('DELETE', `${this.endpoint}/${formId}/elements/${elementId}`);
    updateElement = (formId, elementId, element) =>
        this.request('PUT', `${this.endpoint}/${formId}/elements/${elementId}`, element);
    duplicate = (formId) => this.request('POST', `${this.endpoint}/${formId}/duplicate`);
    publish = (formId) => this.request('POST', `${this.endpoint}/${formId}/publish`);
    submit = (formId, submission) => this.request('POST', `${this.endpoint}/${formId}`, submission);
}

export default new FormApi();
