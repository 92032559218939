import React, { useEffect, useState } from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PublicPages from './components/Access/_models/publicPages';
import ErrorPage from './components/App/_components/error';

export default function Public() {
    // this sends a request to the backend for the projectId
    const [statusCode, setStatusCode] = useState(-1);
    const dispatch = useDispatch();
    const loc = useLocation();
    const path = loc.pathname.slice(8); // the path after '/public/';

    useEffect(() => {
        dispatch(PublicPages.show(path)).then((action) => {
            if (action.type.includes('SUCCESS')) {
                sessionStorage.setItem('projectId', action.permission.projectId);
                setStatusCode(200);
            } else {
                setStatusCode(action.errors?.statusCode || 500);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (statusCode < 0) {
        return null; // loading state goes here
    }
    if (statusCode >= 400) {
        return (
            <ErrorPage
                statusCode={statusCode}
                onGoHome={() => {
                    window.location.href = 'https://www.whitewhale.ai/';
                }}
            />
        );
    }
    return <Outlet />;
}
