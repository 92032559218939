import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import TextAreaInput from '../TextArea';
import ContextMenu from '../../ContextMenu';
import TextInput from '../Text';

require('./styles.scss');

const stringifyJson = (value) => JSON.stringify(value || {}, null, 2);

const JsonInput = (props) => {
    const {
        id,
        value: propValue,
        placeholder,
        onChange,
        icon,
        error,
        disabled,
        gray,
        dark,
        rounded,
        sm,
        validations,
    } = props;

    const [textValue, setTextValue] = useState(stringifyJson(propValue));
    const [open, setOpen] = useState(false);
    const [jsonError, setJsonError] = useState(false);

    useEffect(() => {
        if (stringifyJson(propValue) !== textValue && !open) {
            setTextValue(stringifyJson(propValue));
            setJsonError(false);
        }
    }, [propValue, open, textValue]);

    const validateJson = (value) => {
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            return false;
        }
    };

    const handleTrySubmit = () => {
        if (validateJson(textValue)) {
            onChange(id, JSON.parse(textValue));
        } else {
            setJsonError(true);
        }
    };

    const handleClickOut = () => {
        const isValid = validateJson(textValue);
        if (isValid) {
            handleTrySubmit(textValue);
            setOpen(false);
        } else if (jsonError) {
            setOpen(false);
        } else {
            setJsonError(true);
        }
    };

    const toggleOpen = () => setOpen(!open);

    // ------------------  contextMenu for json input  ----------------------
    const contextMenu = (
        <ContextMenu
            className={ClassNames('JsonInput__menu', {
                'JsonInput__menu--error': error || jsonError,
            })}
            open={open}
            onClickOut={handleClickOut}
        >
            <header className="JsonInput__Title">Please enter valid JSON</header>
            <TextAreaInput
                placeholder="Enter JSON"
                value={textValue}
                validations={validations}
                id={id}
                onChange={(_, value) => setTextValue(value)}
                onBlur={handleTrySubmit}
                error={error || jsonError}
                autoFocus
            />
        </ContextMenu>
    );

    return (
        <div className="JsonInput">
            <div role="button" tabIndex={0} className="JsonInput__label" onClick={() => !disabled && toggleOpen()}>
                <TextInput
                    id={id}
                    sm={sm}
                    value={textValue}
                    placeholder={placeholder}
                    icon={icon}
                    disabled={disabled}
                    gray={gray}
                    dark={dark}
                    rounded={rounded}
                    error={error}
                    size={12}
                />
            </div>
            {contextMenu}
        </div>
    );
};

JsonInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    gray: PropTypes.bool,
    dark: PropTypes.bool,
    rounded: PropTypes.bool,
    sm: PropTypes.bool,
    validations: PropTypes.array,
};

JsonInput.defaultProps = {
    error: false,
    icon: 'code',
    placeholder: '',
    value: {},
    disabled: false,
    gray: false,
    dark: false,
    rounded: false,
    sm: false,
    validations: [],
};

export default JsonInput;
