import config from '../../../config';
import handleApiError from './helpers/error';

export default class ApiBase {
    constructor(endpoint, includeProjectId) {
        this.endpoint = endpoint;
        this.baseUrl = config.app.internalApiHost;
        this.includeProjectId = includeProjectId;
    }

    request = (method, endpoint, obj, options = {}) => {
        let url = this.baseUrl + endpoint;
        if (this.includeProjectId) {
            const projectId = sessionStorage.getItem('projectId');
            if (projectId) {
                url += `${url.includes('?') ? '&' : '?'}projectId=${projectId}`;
            }
        }

        // the "access token" for visiting a "public page" is the resourceId and will be stored in session storage
        // the access token from logging in is a jwt token and is stored in localstorage
        // eslint-disable-next-line no-restricted-globals
        const authorization = location.pathname.includes('public')
            ? sessionStorage.getItem('accessToken')
            : localStorage.getItem('accessToken');
        const headers = new Headers({ authorization });

        let body;

        if (options.contentType === 'FormData') {
            const fd = new FormData();
            let f;
            if (obj?.zipfile || obj?.updatedDependencies || obj?.logo) {
                f = obj;
            } else {
                f = { file: obj };
            }
            Object.keys(f).forEach((key) => fd.append(key, f[key]));
            body = fd;
        } else if (options.contentType === 'FormBlob') {
            body = obj.blob;
        } else {
            body = obj ? JSON.stringify(obj) : null;
            headers.append('content-type', 'application/json');
        }

        return fetch(url, { method, body, headers }).then(
            async (response) => {
                if (response.ok) return response.json();
                const error = await response.json();
                return Promise.reject(
                    handleApiError(
                        { statusCode: response.status, message: error.message || error, attributes: error.attributes },
                        obj,
                    ),
                );
            },
            (error) => {
                // this happens usually when the backend is down
                // and when the backend is down the error object is Type Error: Failed to fetch
                if (error.toString() === 'TypeError: Failed to fetch') {
                    return Promise.reject(handleApiError({ statusCode: 550, message: error }, obj));
                }
                return Promise.reject(handleApiError({ statusCode: 500, message: error }, obj));
            },
        );
    };

    index = (ids) => this.request('GET', `${this.endpoint}${ids ? `?id=${ids.join('&id=')}` : ''}`);
    create = (obj) => this.request('POST', this.endpoint, obj);
    update = (id, obj) => this.request('PUT', `${this.endpoint}/${id}`, obj);
    bulkUpdate = (obj) => this.request('PUT', this.endpoint, obj);
    show = (id) => this.request('GET', `${this.endpoint}/${id}`);
    destroy = (id) => this.request('DELETE', `${this.endpoint}/${id}`);
}
