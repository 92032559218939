import { combineReducers } from 'redux';

import algorithms from '../components/Models/_models/algorithms/reducers';
import app from '../components/Access/_models/app/reducers';
import collections from '../components/Data/_models/collections/reducers';
import dashboards from '../components/Dashboards/_models/dashboards/reducers';
import datasets from '../components/Data/_models/dataset/reducers';
import features from '../components/Data/_models/feature/reducers';
import filters from '../components/Dashboards/_models/filters/reducers';
import forms from '../components/Forms/redux/reducers';
import jobs from '../components/Data/_models/job/reducers';
import login from '../components/Access/_models/login/reducers';
import modelRuns from '../components/Models/_models/modelRuns/reducers';
import models from '../components/Models/_models/models/reducers';
import organizations from '../components/Access/_models/organizations/reducers';
import packages from '../components/Models/_models/packages/reducers';
import permissions from '../components/Projects/_models/permissions/reducers';
import projects from '../components/Projects/_models/projects/reducers';
import publicPages from '../components/Access/_models/publicPages/reducers';
import tabs from '../components/Dashboards/_models/tabs/reducers';
import triggers from '../components/Models/_models/triggers/reducers';
import units from '../components/Units/_model/reducers';
import users from '../components/Access/_models/users/reducers';

const rootReducer = combineReducers({
    algorithms,
    app,
    collections,
    dashboards,
    datasets,
    features,
    filters,
    forms,
    jobs,
    login,
    modelRuns,
    models,
    organizations,
    packages,
    permissions,
    projects,
    publicPages,
    tabs,
    triggers,
    units,
    users,
});

export default rootReducer;
