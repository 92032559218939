import memoize from 'memoize-one';
import { fuzzySearch } from '../../../../helpers/string';

export const applyFilter = memoize((items, filter) => {
    if (typeof items[0] === 'string') {
        // sometimes the items are a list of strings and not objects
        return fuzzySearch(
            filter,
            items.map((value) => ({ label: value, value })),
            ['label', 'value'],
        );
    }
    return fuzzySearch(filter, items, ['label', 'value']);
});

export const isAllSelected = memoize((items, value) =>
    items.length === 0 ? false : items.reduce((acc, item) => acc && value[item.value || item.label || item], true),
);
