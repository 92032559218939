import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class SmallKPI extends PureComponent {
    render() {
        const { label, value, horizontal } = this.props;
        return (
            <div
                className={ClassNames('KPI', 'KPI--small', {
                    'KPI--horizontal': horizontal,
                })}
            >
                <div className="KPI__label">{label}</div>
                <div className="KPI__value">{value}</div>
            </div>
        );
    }
}

SmallKPI.propTypes = {
    label: PropTypes.string,
    value: PropTypes.node,
    horizontal: PropTypes.bool,
};

SmallKPI.defaultProps = {
    label: '',
    value: '',
    horizontal: false,
};
