import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ToolTip from '../ToolTip';
import Icon from '../Icon';

export default class InfoToolTip extends PureComponent {
    render() {
        const { children, place, size, arrow } = this.props;
        return (
            <ToolTip tooltip={children} place={place} arrow={arrow}>
                <Icon icon="info" size={size} />
            </ToolTip>
        );
    }
}

InfoToolTip.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
    place: PropTypes.string,
    arrow: PropTypes.bool,
};

InfoToolTip.defaultProps = {
    size: '16px',
    place: 'bottom',
    arrow: true,
};
