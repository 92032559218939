import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class ListItem extends PureComponent {
    handleClick = (e) => {
        const { onClick, id } = this.props;
        e.preventDefault();
        e.stopPropagation();
        onClick(id, e);
    };
    handleRightClick = (e) => {
        const { onRightClick, id } = this.props;
        if (onRightClick) {
            e.preventDefault();
            onRightClick(id, e);
        }
    };

    render() {
        const { name, type, decorations, style, active, dark, gray, sm, loading } = this.props;
        return (
            <div
                className={ClassNames('ListItem', {
                    'ListItem--active': active,
                    'ListItem--loading': loading,
                    'ListItem--dark': dark,
                    'ListItem--gray': gray,
                    'ListItem--default': !(dark || gray),
                    'ListItem--sm': sm,
                })}
                onClick={this.handleClick}
                onContextMenu={this.handleRightClick}
                role="menuitem"
                tabIndex={0}
                style={style}
            >
                {type && <span className="ListItem__type"> {type} </span>}
                <span className="ListItem__name"> {name} </span>
                {decorations && <span className="ListItem__decorations"> {decorations} </span>}
            </div>
        );
    }
}

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.node,
    decorations: PropTypes.node,
    style: PropTypes.shape({}),
    active: PropTypes.bool,
    loading: PropTypes.bool,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    sm: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onRightClick: PropTypes.func,
};
ListItem.defaultProps = {
    type: null,
    decorations: null,
    style: {},
    active: false,
    loading: false,
    dark: false,
    gray: false,
    sm: false,
    onRightClick: () => {},
};
