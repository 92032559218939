import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import IconButton from '../../IconButton';

require('./styles.scss');

export default class ButtonListInput extends Component {
    handleClick = (value) => {
        const { value: propsValue, preventNoValue, id, onChange } = this.props;
        const clickedInactiveButton = value !== propsValue;
        if (preventNoValue && !clickedInactiveButton) return;
        onChange(id, clickedInactiveButton ? value : '');
    };

    render() {
        const { buttons, gray, dark, value } = this.props;
        return (
            <div className={ClassNames('ButtonList', { 'ButtonList--gray': gray })}>
                {buttons.map((button) => (
                    <IconButton
                        key={button.id}
                        {...button}
                        dark={dark}
                        selected={button.id === value}
                        onClick={this.handleClick}
                    />
                ))}
            </div>
        );
    }
}

ButtonListInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    preventNoValue: PropTypes.bool,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            onClick: PropTypes.func,
            label: PropTypes.node,
        }),
    ).isRequired,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
};

ButtonListInput.defaultProps = {
    dark: false,
    gray: false,
    value: null,
    preventNoValue: false,
};
