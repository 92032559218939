// code from gblazex https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily

const keys = { 38: true, 40: true }; // May 2020: removed left and right arrow keys because it was preventing arrow keys in inputs from working.
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36

function defaultPreventDefault(e = window.event) {
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
}

function preventDefaultForScrollKeys(e, preventDefault) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        // return false;
    }
}

export function scrollHelpers(customPreventDefault) {
    const preventDefault = customPreventDefault || defaultPreventDefault;
    const keydownEventListener = (e) => preventDefaultForScrollKeys(e, preventDefault);

    const disableScroll = () => {
        if (window.addEventListener) {
            // older FF
            window.addEventListener('DOMMouseScroll', preventDefault, { passive: false, capture: false });
        }
        // older browsers, IE:
        window.addEventListener('mousewheel', preventDefault, { capture: false, passive: false });
        document.addEventListener('mousewheel', preventDefault, { capture: false, passive: false });
        // modern standard:
        window.addEventListener('wheel', preventDefault, { capture: false, passive: false });
        // mobile:
        window.addEventListener('touchmove', preventDefault, { capture: false, passive: false });
        document.addEventListener('keydown', keydownEventListener, {
            capture: false,
            passive: false,
        });
    };

    const enableScroll = () => {
        if (window.removeEventListener)
            window.removeEventListener('DOMMouseScroll', preventDefault, { passive: false, capture: false });
        window.removeEventListener('mousewheel', preventDefault, { passive: false, capture: false });
        document.removeEventListener('mousewheel', preventDefault, { passive: false, capture: false });
        window.removeEventListener('wheel', preventDefault, { passive: false, capture: false });
        window.removeEventListener('touchmove', preventDefault, { passive: false, capture: false });
        document.removeEventListener('keydown', keydownEventListener, { passive: false, capture: false });
    };

    return { disableScroll, enableScroll };
}
