import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../shared';
import { errorPages } from './constants';

require('./styles.scss');

export default function ErrorPage({ statusCode, onGoHome }) {
    const navigate = useNavigate();
    let errorType;
    if (statusCode === 440) {
        // auth token error
        navigate('/login');
        return null;
    }
    if (statusCode === 404) {
        errorType = 'notFound';
    } else if (statusCode === 502 || statusCode === 503 || statusCode === 550) {
        errorType = 'maintenance';
    } else if (statusCode < 500) {
        errorType = 'badRequest';
    } else {
        errorType = 'default';
    }

    const { exclamation, message, whale } = errorPages[errorType];
    const handleContactSupport = () => window.open('http://whitewhale.ai/contact', '_blank');

    return (
        <div
            className={ClassNames('ErrorPage', {
                'ErrorPage--red': statusCode !== 404,
                'ErrorPage--warn': statusCode === 404,
            })}
        >
            <div className="ErrorPage__container">
                <div className="ErrorPage__text">
                    <header className="ErrorPage__title">{exclamation}</header>
                    <main className="ErrorPage__message">
                        {message}
                        <div className="ErrorPage__errorCode">
                            Error code: <strong>{statusCode}</strong>
                        </div>
                    </main>
                    <footer className="ErrorPage__buttons">
                        <Button blue onClick={onGoHome || (() => navigate('/projects'))}>
                            Go to Home
                        </Button>
                        <Button gray onClick={handleContactSupport}>
                            Contact Support
                        </Button>
                    </footer>
                </div>
            </div>
            <img src={whale} alt={`ErrorPage__whale-${statusCode}`} />
        </div>
    );
}

ErrorPage.propTypes = {
    statusCode: PropTypes.number,
    onGoHome: PropTypes.func,
};
ErrorPage.defaultProps = {
    statusCode: 200,
    onGoHome: null,
};
