import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import moment from 'moment/moment';
import { getYearCalendar } from './helper';

require('./styles.scss');

export default class YearCalendar extends Component {
    isWithinRange = (date) => {
        const { min: propsMin, max: propsMax } = this.props;
        const min = propsMin ? moment(propsMin) : { isValid: () => false };
        const max = propsMax ? moment(propsMax) : { isValid: () => false };
        if (min.isValid()) return max.isValid() ? date.isBetween(min, max) : date.isAfter(min);
        return max.isValid() ? date.isBefore(max) : true;
    };

    render() {
        const { value, selectDate } = this.props;

        const date = value && moment(value).isValid() ? moment(value).startOf('month') : moment().startOf('month');
        const yearCalendar = getYearCalendar(date);

        return (
            <div className={ClassNames('YearCalendar')}>
                {yearCalendar.map((d) => (
                    <div
                        key={d}
                        className={ClassNames('YearCalendar__month', {
                            'YearCalendar__month--current': d.isSame(date),
                            'YearCalendar__month--invalid': !this.isWithinRange(d),
                        })}
                        role="button"
                        onClick={() => selectDate(d)}
                        tabIndex={-1}
                    >
                        {d.format('MMM')}
                    </div>
                ))}
            </div>
        );
    }
}

YearCalendar.propTypes = {
    value: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    selectDate: PropTypes.func,
};

YearCalendar.defaultProps = {
    value: '',
    min: undefined,
    max: undefined,
    selectDate: () => {},
};
