import AutosizeInput from 'react-input-autosize';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class AutosizeTextInput extends PureComponent {
    render() {
        const { className, maxWidth, ...inputProps } = this.props;
        const style = { textOverflow: 'inherit' };
        if (maxWidth) style.maxWidth = maxWidth;
        return (
            <AutosizeInput
                ref={this.autosizeRef}
                {...inputProps}
                className="AutosizeInputContainer"
                inputClassName={className}
                inputStyle={style}
            />
        );
    }
}

AutosizeTextInput.propTypes = {
    className: PropTypes.string.isRequired,
    maxWidth: PropTypes.number,
};

AutosizeTextInput.defaultProps = {
    maxWidth: undefined,
};
