import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class Button extends PureComponent {
    handleClick = (e) => {
        const { onClick, id } = this.props;
        e.preventDefault();
        e.stopPropagation();
        onClick(id, e);
    };
    handleRightClick = (e) => {
        const { onRightClick, id } = this.props;
        if (onRightClick) {
            e.preventDefault();
            onRightClick(id, e);
        }
    };

    render() {
        const {
            blue,
            gray,
            dark,
            green,
            disabled,
            red,
            loading,
            loadingText,
            tabIndex,
            id,
            small,
            large,
            className,
            children,
        } = this.props;
        return (
            <button
                onClick={this.handleClick}
                onContextMenu={this.handleRightClick}
                className={ClassNames('Button', className, {
                    'Button--blue': blue,
                    'Button--gray': gray,
                    'Button--dark': dark,
                    'Button--green': green,
                    'Button--red': red,
                    'Button--disabled': disabled,
                    'Button--loading': loading,
                    'Button--small': small,
                    'Button--large': large && !small,
                })}
                disabled={disabled || loading}
                tabIndex={tabIndex}
                type="button"
                id={id}
            >
                {loading ? loadingText : children}
            </button>
        );
    }
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    onRightClick: PropTypes.func,
    blue: PropTypes.bool,
    gray: PropTypes.bool,
    dark: PropTypes.bool,
    green: PropTypes.bool,
    disabled: PropTypes.bool,
    red: PropTypes.bool,
    tabIndex: PropTypes.number,
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    small: PropTypes.bool,
    large: PropTypes.bool,
};

Button.defaultProps = {
    className: null,
    blue: false,
    gray: false,
    green: false,
    dark: false,
    disabled: false,
    red: false,
    id: '',
    tabIndex: 0,
    onClick: () => {},
    onRightClick: null,
    loading: false,
    loadingText: 'Loading...',
    small: false,
    large: true,
};
