import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

require('./styles.scss');

const getShadowOpacities = (scrollStartPosition, currentPosition, scrollContainerSize) => {
    const startOpacity = (1 / 40) * Math.min(scrollStartPosition, 40);
    const distanceToEnd = currentPosition - scrollContainerSize;
    const endOpacity = (1 / 40) * (distanceToEnd - Math.max(scrollStartPosition, distanceToEnd - 40));
    return { startOpacity, endOpacity };
};

export default function ScrollArea(props) {
    const { children, childType, className, dark, setRef, scrollX, scrollY } = props;

    const [topOpacity, setTopOpacity] = useState(0);
    const [bottomOpacity, setBottomOpacity] = useState(0);
    const [leftOpacity, setLeftOpacity] = useState(0);
    const [rightOpacity, setRightOpacity] = useState(0);

    const handleScrollY = (container) => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const { startOpacity, endOpacity } = getShadowOpacities(scrollTop, scrollHeight, clientHeight);
        setTopOpacity(startOpacity);
        setBottomOpacity(endOpacity);
    };
    const handleScrollX = (container) => {
        const { scrollLeft, scrollWidth, clientWidth } = container;
        const { startOpacity, endOpacity } = getShadowOpacities(scrollLeft, scrollWidth, clientWidth);
        setLeftOpacity(startOpacity);
        setRightOpacity(endOpacity);
    };

    // run the gradient calculations on the scroll box DOM element on mount
    const scrollBox = useRef(null);
    useEffect(() => {
        const domElement = scrollBox.current ? scrollBox.current._container : {};
        setRef(domElement);
        handleScrollY(domElement);
        handleScrollX(domElement);
    }, [setRef]);

    // run the gradient calculations when the scrollable content changes (ex: refresh, after loading)
    const handleSync = (scrollbar) => {
        if (scrollY) handleScrollY(scrollbar.element);
        if (scrollX) handleScrollX(scrollbar.element);
    };

    return (
        <div className={ClassNames('ScrollArea', className, { 'ScrollArea--dark': dark })}>
            <PerfectScrollbar
                component={childType}
                options={{ suppressScrollX: !scrollX, suppressScrollY: !scrollY }}
                onScrollY={handleScrollY}
                onScrollX={handleScrollX}
                onSync={handleSync}
                ref={scrollBox}
            >
                {children}
            </PerfectScrollbar>
            {scrollY && (
                <React.Fragment>
                    <div className="ScrollArea__topGradient" style={{ opacity: topOpacity }} />
                    <div className="ScrollArea__bottomGradient" style={{ opacity: bottomOpacity }} />
                </React.Fragment>
            )}
            {scrollX && (
                <React.Fragment>
                    <div className="ScrollArea__leftGradient" style={{ opacity: leftOpacity }} />
                    <div className="ScrollArea__rightGradient" style={{ opacity: rightOpacity }} />
                </React.Fragment>
            )}
        </div>
    );
}

ScrollArea.propTypes = {
    children: PropTypes.node,
    childType: PropTypes.string,
    className: PropTypes.string,
    dark: PropTypes.bool,
    scrollX: PropTypes.bool,
    scrollY: PropTypes.bool,
    setRef: PropTypes.func,
};

ScrollArea.defaultProps = {
    children: null,
    childType: 'div',
    className: '',
    dark: false,
    scrollX: true,
    scrollY: true,
    setRef: () => {},
};
