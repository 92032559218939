import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import TextInput from '../Text';

require('./styles.scss');

export default class EditableText extends Component {
    constructor(props) {
        super(props);
        const { autoFocus } = this.props;
        this.state = { expanded: autoFocus };
    }
    toggleExpanded = () => {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    };
    handleKeyPress = (e) => {
        const { submitOnBlur, id } = this.props;
        if (e.key === 'Enter') {
            e.preventDefault();
            if (!submitOnBlur) this.handleSubmit(id);
            e.target.blur();
        }
    };
    handleBlur = (e) => {
        const { onBlur } = this.props;
        this.setState({ expanded: false });
        onBlur(e);
    };
    handleSubmit = (id) => {
        const { onSubmit } = this.props;
        this.setState({ expanded: false });
        onSubmit(id);
    };
    render() {
        const { value } = this.props;
        const { expanded } = this.state;
        return (
            <div
                className={ClassNames('EditableText', {
                    'EditableText--resting': !expanded,
                    'EditableText--empty': !value,
                })}
            >
                <TextInput
                    {...this.props}
                    onSubmit={this.handleSubmit}
                    onKeyPress={this.handleKeyPress}
                    onFocus={this.toggleExpanded}
                    onBlur={this.handleBlur}
                />
            </div>
        );
    }
}

EditableText.propTypes = {
    id: PropTypes.string.isRequired,
    error: PropTypes.bool,
    autoFocus: PropTypes.bool,
    submitOnBlur: PropTypes.bool,
    onSubmit: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
};

EditableText.defaultProps = {
    error: false,
    autoFocus: false,
    submitOnBlur: false,
    onSubmit: () => {},
    onBlur: () => {},
    value: '',
};
