export const initialState = (additionalStateValues = {}) => ({
    byId: {},
    allIds: [],
    errors: {},
    hydrated: false,
    loading: false,
    _tmp: {},
    ...additionalStateValues,
});

export default initialState;
