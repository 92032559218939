import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon';
import ContextMenu from '..';
import ContextSubMenu from '../ContextSubMenu';
import ContextMenuItem from '../ContextMenuItem';

export default function RightClickMenu(props) {
    const { open, onClickOut, coordinates, items, title } = props;

    return (
        <ContextMenu open={open} onClickOut={onClickOut} coordinates={coordinates} listHeading={title}>
            <ContextSubMenu>
                {items.map((item) => (
                    <ContextMenuItem
                        id={item.id}
                        key={item.id}
                        disabled={item.disabled}
                        onClick={item.onClick}
                        type={item.icon && <Icon size="20px" icon={item.icon} />}
                        name={item.label}
                    />
                ))}
            </ContextSubMenu>
        </ContextMenu>
    );
}
RightClickMenu.propTypes = {
    open: PropTypes.bool,
    onClickOut: PropTypes.func,
    coordinates: PropTypes.shape({}),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            disabled: PropTypes.bool,
            icon: PropTypes.string,
            onClick: PropTypes.func,
            data: PropTypes.shape({}),
        }),
    ),
    title: PropTypes.string,
};

RightClickMenu.defaultProps = {
    open: false,
    onClickOut: () => {},
    coordinates: {},
    items: {},
    title: '',
};
