import moment from 'moment/moment';

export function getYearCalendar(dateInput) {
    const firstDay = dateInput.clone().startOf('year').startOf('month');
    const lastDay = dateInput.clone().endOf('year').startOf('month');
    const arrayOfDays = [];
    for (const d = moment(firstDay); d.isSameOrBefore(lastDay); d.add(1, 'month')) {
        arrayOfDays.push(d.clone());
    }
    return arrayOfDays;
}
