import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import Icon from '../../Icon';
import Button from '../../Button';
import IconButton from '../../IconButton';
import Section from '../../Section';

require('./styles.scss');

export default class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            imgPreviewData:
                props.value && props.value.type.startsWith('image/') ? window.URL.createObjectURL(props.value) : '',
        };

        this.hiddenFileInput = null;
        this.setHiddenFileInputRef = (element) => {
            this.hiddenFileInput = element;
        };
    }

    componentWillUnmount() {
        const { imgPreviewData } = this.state;
        if (imgPreviewData) window.URL.revokeObjectURL(imgPreviewData);
    }

    openFileSelector = () => {
        if (this.hiddenFileInput) this.hiddenFileInput.click();
    };

    handleClose = () => {
        const { onChange, id } = this.props;
        this.hiddenFileInput.value = '';
        onChange(id, null);
    };
    handleDragOver = (e) => {
        const { hover } = this.state;
        if (!hover) this.setState({ hover: true });
        e.preventDefault();
    };

    handleDragLeave = () => {
        this.setState({ hover: false });
    };

    handleDataDrop = (e) => {
        e.preventDefault();
        const dt = e.dataTransfer;
        const { files } = dt;
        this.setState({ hover: false });
        this.handleChange(files);
    };

    handleChange = (files) => {
        // called when file is selected through file selection window or drag & drop
        const { id, onChange } = this.props;
        const { imgPreviewData } = this.state;

        const file = files[0];

        window.URL.revokeObjectURL(imgPreviewData);
        if (file.type.startsWith('image/')) {
            const newImgUrl = window.URL.createObjectURL(file);
            this.setState({ imgPreviewData: newImgUrl });
        } else {
            this.setState({ imgPreviewData: '' });
        }
        onChange(id, file);
    };

    render() {
        const { id, accept, value, errors, validations } = this.props;
        const { imgPreviewData, hover } = this.state;
        const hasError = errors && errors.length > 0;

        const restrictionString = validations.map((v) => v.label).join(', ');
        const Restrictions = restrictionString && <div className="FileUpload__restrictions">{restrictionString}</div>;

        const EmptyStateInner = !value && (
            <Fragment>
                <Icon icon="large_color/file-upload" size="150px" />
                <div className="FileUpload__message"> Drag and Drop File </div>
                <div className="FileUpload__messageChooseFile">
                    <div className="FileUpload__messageSeperator"> or </div>
                    <Button onClick={this.openFileSelector} gray>
                        Choose File
                    </Button>
                </div>
                {Restrictions}
            </Fragment>
        );
        const ErrorStateInner = hasError && (
            <Fragment>
                <Icon icon="large_color/file-upload-failed" size="150px" />
                <span className="FileUpload__messageError">{errors.map((error) => error.message).join(', ')}</span>
                <Button onClick={this.openFileSelector} id="retry" gray>
                    Upload File
                </Button>
                {Restrictions}
            </Fragment>
        );
        const UploadedStateInner = value && (
            <Fragment>
                {imgPreviewData ? (
                    <div className="FileUpload__ImageDiv">
                        <img id="FileUpload__ImagePreview" alt="No preview available" src={imgPreviewData} />
                    </div>
                ) : (
                    <Icon icon="large_color/file-upload-success" size="150px" />
                )}
                <div className="FileUpload__fileName">{value.name}</div>
                <div>{numeral(value.size).format('0.0 b')}</div>
            </Fragment>
        );

        return (
            <div className="FileUpload">
                <input
                    accept={accept}
                    type="file"
                    id={id}
                    onChange={(e) => this.handleChange(e.target.files)}
                    ref={this.setHiddenFileInputRef}
                />
                <Section
                    selected={!!value || hover}
                    unselected={!value && !hasError && !hover}
                    error={!value && hasError && !hover}
                >
                    {UploadedStateInner && <IconButton onClick={this.handleClose} icon="times" redAlert />}
                    <div
                        className="FileUpload__container"
                        onDragOver={this.handleDragOver}
                        onDragLeave={this.handleDragLeave}
                        onDrop={this.handleDataDrop}
                    >
                        {ErrorStateInner || UploadedStateInner || EmptyStateInner}
                    </div>
                </Section>
            </div>
        );
    }
}

FileUpload.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]),
    errors: PropTypes.arrayOf(PropTypes.shape({})),
    accept: PropTypes.string,
    validations: PropTypes.arrayOf(
        PropTypes.shape({
            validate: PropTypes.func,
            label: PropTypes.string,
        }),
    ),
    onChange: PropTypes.func,
};

FileUpload.defaultProps = {
    validations: [],
    value: null,
    errors: [],
    accept: '*',
    onChange: () => {},
};
