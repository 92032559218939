import { createReducer } from 'reduxsauce';
import { actions, makeCrudReducers, initialState, apiHandlers } from '../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../Projects/_models/projects/reducers';
import Schema from './schema';

const INITIAL_STATE = initialState();

// ---------------- ACTION HANDLERS ----------------

// ---------------- CREATE ACTIONS ----------------

const { Creators, Types } = actions('form', {
    createElementRequest: ['id', 'element', 'index'],
    createElementSuccess: ['id', 'form'],
    createElementFailure: ['id', 'errors'],
    deleteElementRequest: ['id', 'elementId'],
    deleteElementSuccess: ['id', 'elementId', 'form'],
    deleteElementFailure: ['id', 'elementId', 'errors'],
    updateElementRequest: ['id', 'elementId', 'element'],
    updateElementSuccess: ['id', 'elementId', 'form'],
    updateElementFailure: ['id', 'elementId', 'errors'],
    duplicateRequest: ['id'],
    duplicateSuccess: ['id', 'form'],
    duplicateFailure: ['id', 'errors'],
    publishRequest: ['id'],
    publishSuccess: ['id', 'form'],
    publishFailure: ['id', 'errors'],
    submitRequest: ['id', 'submission'],
    submitSuccess: ['id', 'response'],
    submitFailure: ['id', 'errors'],
});
export { Creators as Actions, Types };

const crudReducers = makeCrudReducers('form', INITIAL_STATE, Schema, Types);

const createElementRequest = (state, { id, element, index }) => {
    const form = state.byId[id];
    let updatedElements = [...form.elements];
    if (index) {
        updatedElements.splice(index, 0, element);
    } else {
        updatedElements = [...form.elements, element];
    }
    return crudReducers[Types.UPDATE_REQUEST](state, {
        id,
        form: { ...form, elements: updatedElements },
    });
};
const deleteElementRequest = (state, { id, elementId }) => {
    const form = state.byId[id];
    return crudReducers[Types.UPDATE_REQUEST](state, {
        id,
        form: { ...form, elements: form.elements.filter((element) => element._id !== elementId) },
    });
};
const updateElementRequest = (state, { id, elementId, element: updates }) => {
    const form = state.byId[id];
    return crudReducers[Types.UPDATE_REQUEST](state, {
        id,
        form: {
            ...form,
            elements: form.elements.map((element) =>
                element._id === elementId ? { ...element, ...updates } : element,
            ),
        },
    });
};

// the crudReducers[update_success] does not load any information from `action.form`.  This does:
const updateSuccess = (state, action) =>
    crudReducers[Types.UPDATE_SUCCESS](crudReducers[Types.UPDATE_REQUEST](state, action), action);

const additionalReducers = apiHandlers(['DUPLICATE', 'PUBLISH', 'SUBMIT'], Types, {
    [Types.CREATE_ELEMENT_REQUEST]: createElementRequest,
    [Types.CREATE_ELEMENT_SUCCESS]: updateSuccess,
    [Types.CREATE_ELEMENT_FAILURE]: crudReducers[Types.UPDATE_FAILURE],
    [Types.UPDATE_ELEMENT_REQUEST]: updateElementRequest,
    [Types.UPDATE_ELEMENT_SUCCESS]: updateSuccess,
    [Types.UPDATE_ELEMENT_FAILURE]: crudReducers[Types.UPDATE_FAILURE],
    [Types.DELETE_ELEMENT_REQUEST]: deleteElementRequest,
    [Types.DELETE_ELEMENT_SUCCESS]: updateSuccess,
    [Types.DELETE_ELEMENT_FAILURE]: crudReducers[Types.UPDATE_FAILURE],
    [Types.DUPLICATE_SUCCESS]: crudReducers[Types.CREATE_SUCCESS],
    [Types.PUBLISH_SUCCESS]: updateSuccess,
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('forms'),
});

export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
