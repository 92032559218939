import React from 'react';
import PropTypes from 'prop-types';

import { ModalCard, ScrollArea } from '../../shared';

import './styles.scss';

const FormPrivacyModal = ({ open, onClose }) => (
    <ModalCard open={open} onClose={onClose} title="Privacy Policy">
        <ScrollArea className="FormDisplay__modal">
            <p>Last updated: March 23, 2021</p>
            <p>
                This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about Your privacy rights and how the law protects
                You.
            </p>
            <p>
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this Privacy Policy.
            </p>
            <h3>Interpretation and Definitions</h3>
            <h4>Interpretation</h4>
            <p>
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.
            </p>
            <h4>Definitions</h4>
            <p>For the purposes of this Privacy Policy:</p>
            <p>
                <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or
                other securities entitled to vote for election of directors or other managing authority.
            </p>
            <p>
                <strong>Application</strong> means the cloud-based software program provided by the Company, accessed by
                You on any electronic device, named DeepSea.
            </p>
            <p>
                <strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;
                or &quot;Our&quot; in this Agreement) refers to White Whale Analytics Ltd. , 925 7th Ave SW #308,
                Calgary T2P 2B5.
                <br />
                For the purpose of the GDPR, the Company is the Data Processor.
            </p>
            <p>
                <strong>Country</strong> refers to: Alberta, Canada
            </p>
            <p>
                <strong>Data Controller</strong> for the purposes of the GDPR (General Data Protection Regulation),
                refers to the third party Service Provider as the legal person which determines the purposes and means
                of the processing of personal data.
            </p>
            <p>
                <strong>Data Processor</strong> for the purposes of the GDPR (General Data Protection Regulation),
                refers to the Company as the legal person which processes personal data on behalf of the Data
                Controller.
            </p>
            <p>
                <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or
                a digital tablet.
            </p>
            <p>
                <strong>Form</strong> means an electronic form made accessible to You as a way to submit data, to be
                collected by Us, on behalf of the Data Controller, as part of our Service.
            </p>
            <p>
                <strong>Personal Data</strong> is any information that relates to an identified or identifiable
                individual.For the purposes for GDPR, Personal Data means any information relating to You such as a
                name, an identification number, location data, online identifier or to one or more factors specific to
                the physical, physiological, genetic, mental, economic, cultural or social identity.
            </p>
            <p>
                <strong>Service</strong> refers to the Application.
            </p>
            <p>
                <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of
                the Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                Service, to provide the Service on behalf of the Company, to perform services related to the Service or
                to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service
                Providers are considered Data Processors.
            </p>
            <p>
                <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example, the duration of a page visit).
            </p>
            <p>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal
                entity on behalf of which such individual is accessing or using the Service, as applicable. <br />
                Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the
                User as you are the individual using the Service.
            </p>
            <h3>Collecting and Using Your Personal Data</h3>
            <h4>Types of Data Collected</h4>
            <h5>Personal Data</h5>
            <p>
                While using Our Service, We may ask You to provide Us with certain personally identifiable information
                that can be used to contact or identify You. Personally identifiable information may include, but is not
                limited to:
            </p>
            <p>
                Email address
                <br />
                First name and last name
                <br />
                Phone number
                <br />
                Address, State, Province, ZIP/Postal code, City
                <br />
                Form Data <br />
                Survey Data
                <br />
                Usage Data
            </p>
            <h5>Usage Data</h5>
            <p>
                Usage Data is collected automatically when using the Service.
                <br />
                Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
                the time spent on those pages, unique device identifiers and other diagnostic data.
            </p>
            <p>
                When You access the Service by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other diagnostic data.
            </p>
            <p>
                We may also collect information that Your browser sends whenever You visit our Service or when You
                access the Service by or through a mobile device.
            </p>
            <h4>Use of Your Personal Data</h4>
            <p>The Company may use Personal Data for the following purposes:</p>
            <p>
                <strong>To provide and maintain our Service</strong> including to fulfill the requirements at the
                request of the Data Controller and to monitor the usage of our Service.
            </p>
            <p>
                <strong>For the performance of a contract:</strong> the development, compliance and undertaking of the
                purchase contract for the products, items or services You have purchased or of any other contract with
                Us through the Service.
            </p>
            <p>
                <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent
                forms of electronic communication, such as a mobile application’s push notifications regarding updates
                or informative communications related to the functionalities, products or contracted services, including
                the security updates, when necessary or reasonable for their implementation.
            </p>
            <p>
                <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
            </p>
            <p>
                <strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in
                which Personal Data held by Us about our Service users is among the assets transferred.
            </p>
            <p>
                <strong>For other purposes:</strong> We may use Your information for other purposes, such as data
                analysis, identifying usage trends, to evaluate and improve our Service, products, services, marketing
                and your customer experience.
            </p>
            <p>
                <strong>We may share Your personal information in the following situations:</strong>
            </p>
            <p>
                <strong>With Service Providers:</strong> We may share Your personal information with Service Providers
                to monitor and analyze the use of our Service, to contact You.
            </p>
            <p>
                <strong>For business transfers:</strong> We may share or transfer Your personal information in
                connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                acquisition of all or a portion of Our business to another company.
            </p>
            <p>
                <strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we
                will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and
                any other subsidiaries, joint venture partners or other companies that We control or that are under
                common control with Us.
            </p>
            <p>
                <strong>With other users:</strong> when You share personal information or otherwise interact in the
                public areas with other users, such information may be viewed by all users and may be publicly
                distributed outside.
            </p>
            <p>
                <strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with
                Your consent.
            </p>
            <h4>Retention of Your Personal Data</h4>
            <p>
                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with
                our legal obligations (for example, if we are required to retain your data to comply with applicable
                laws), resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p>
                The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
                for a shorter period of time, except when this data is used to strengthen the security or to improve the
                functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>
            <h4>Transfer of Your Personal Data</h4>
            <p>
                Your information, including Personal Data, is processed at the Company’s operating offices and in any
                other places where the parties involved in the processing are located. It means that this information
                may be transferred to — and maintained on — computers located outside of Your state, province, country
                or other governmental jurisdiction where the data protection laws may differ than those from Your
                jurisdiction.
            </p>
            <p>
                Your consent to this Privacy Policy followed by Your submission of such information represents Your
                agreement to that transfer.
            </p>
            <p>
                The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
                accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of Your
                data and other personal information.
            </p>
            <h4>Disclosure of Your Personal Data</h4>
            <h5>Business Transactions</h5>
            <p>
                If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
                transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a
                different Privacy Policy.
            </p>
            <h5>Law enforcement</h5>
            <p>
                Under certain circumstances, the Company may be required to disclose Your Personal Data if required to
                do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).
            </p>
            <h5>Other legal requirements</h5>
            <p>
                The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </p>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
            <h4>Security of Your Personal Data</h4>
            <p>
                The security of Your Personal Data is important to Us, but remember that no method of transmission over
                the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
                acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
            </p>
            <h3>Detailed Information on the Processing of Your Personal Data</h3>
            <p>
                The Service Providers We use may have access to Your Personal Data. These third-party vendors collect,
                store, use, process and transfer information about Your activity on Our Service in accordance with their
                Privacy Policies.
            </p>
            <h4>Email Service Providers</h4>
            <p>We may use Email Marketing Service Providers to manage and send emails to You.</p>
            <h5>Sendgrid</h5>
            <p>
                Their Privacy Policy can be viewed at <br />
                https://www.twilio.com/legal/privacy
            </p>
            <h3>GDPR Privacy</h3>
            <h4>Legal Basis for Processing Personal Data under GDPR</h4>
            <p>We may process Personal Data under the following conditions:</p>
            <p>Consent: You have given Your consent for processing Personal Data for one or more specific purposes.</p>
            <p>
                Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement
                with You and/or for any pre-contractual obligations thereof.
            </p>
            <p>
                Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which
                the Company is subject.
            </p>
            <p>
                Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of
                another natural person.
            </p>
            <p>
                Public interests: Processing Personal Data is related to a task that is carried out in the public
                interest or in the exercise of official authority vested in the Company.
            </p>
            <p>
                Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests
                pursued by the Company.
            </p>
            <p>
                In any case, the Company will gladly help to clarify the specific legal basis that applies to the
                processing, and in particular whether the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract.
            </p>
            <h4>Your Rights under the GDPR</h4>
            <p>
                The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can
                exercise Your rights.
            </p>
            <p>You have the right under this Privacy Policy, and by law if You are within the EU, to:</p>
            <p>
                <li>
                    <strong>Request access to Your Personal Data.</strong> The right to access, update or delete the
                    information We have on You. Please contact Us to assist You. This also enables You to receive a copy
                    of the Personal Data We hold about You.
                </li>
            </p>
            <p>
                <li>
                    <strong>Request correction of the Personal Data that We hold about You.</strong> You have the right
                    to to have any incomplete or inaccurate information We hold about You corrected.
                </li>
            </p>
            <p>
                <li>
                    <strong>Object to processing of Your Personal Data.</strong> This right exists where We are relying
                    on a legitimate interest as the legal basis for Our processing and there is something about Your
                    particular situation, which makes You want to object to our processing of Your Personal Data on this
                    ground. You also have the right to object where We are processing Your Personal Data for direct
                    marketing purposes.
                </li>
            </p>
            <p>
                <li>
                    <strong>Request erasure of Your Personal Data.</strong> You have the right to ask Us to delete or
                    remove Personal Data when there is no good reason for Us to continue processing it.
                </li>
            </p>
            <p>
                <li>
                    <strong>Request the transfer of Your Personal Data.</strong> We will provide to You, or to a
                    third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable
                    format. Please note that this right only applies to automated information which You initially
                    provided consent for Us to use or where We used the information to perform a contract with You.
                </li>
            </p>
            <p>
                <li>
                    <strong>Withdraw Your consent.</strong> You have the right to withdraw Your consent on using your
                    Personal Data. If You withdraw Your consent, We may not be able to provide You with access to
                    certain specific functionalities of the Service.
                </li>
            </p>
            <h4>Exercising of Your GDPR Data Protection Rights</h4>
            <p>
                You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
                Please note that we may ask You to verify Your identity before responding to such requests. If You make
                a request, We will try our best to respond to You as soon as possible.
            </p>
            <p>
                You have the right to complain to a Data Protection Authority about Our collection and use of Your
                Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your
                local data protection authority in the EEA.
            </p>
            <h3>Children’s Privacy</h3>
            <p>
                The Service may contain content appropriate for children under the age of 13. As a parent, you should
                know that through the Service children under the age of 13 may participate in activities that involve
                the collection or use of personal information. We use reasonable efforts to ensure that before we
                collect any personal information from a child, the child’s parent receives notice of and consents to our
                personal information practices.
            </p>
            <p>
                We also may limit how We collect, use, and store some of the information of Users between 13 and 18
                years old. In some cases, this means We will be unable to provide certain functionality of the Service
                to these Users. If We need to rely on consent as a legal basis for processing Your information and Your
                country requires consent from a parent, We may require Your parent’s consent before We collect and use
                that information.
            </p>
            <h4>Information Collected from Children Under the Age of 13</h4>
            <p>
                The Company may collect and store persistent identifiers such as cookies or IP addresses from Children
                without parental consent for the purpose of supporting the internal operation of the Service.
            </p>
            <p>
                We may collect and store other personal information about children if this information is submitted by a
                child with prior parent consent or by the parent or guardian of the child.
            </p>
            <p>
                The Company may collect and store the following types of personal information about a child when
                submitted by a child with prior parental consent or by the parent or guardian of the child:
            </p>
            <li>First and/or last name</li>
            <li>Date of birth</li>
            <li>Gender</li>
            <li>Grade level</li>
            <li>Email address</li>
            <li>Telelihone number</li>
            <li>Parent’s or guardian’s name</li>
            <li>Parent’s or guardian’s email address</li>
            <p>
                For further details on the information We might collect, You can refer to the &quot;Types of Data
                Collected&quot; section of this Privacy Policy. We follow our standard Privacy Policy for the disclosure
                of personal information collected from and about children.
            </p>
            <h4>Parental Access</h4>
            <p>
                A parent who has already given the Company permission to collect and use his child’s personal
                information can, at any time:
            </p>
            <li>Review, correct or delete the child’s personal information</li>
            <li>Discontinue further collection or use of the child’s personal information</li>
            <p>
                To make such a request, You can write to Us using the contact information provided in this Privacy
                Policy.
            </p>
            <h3>Links to Other Websites</h3>
            <p>
                Our Service may contain links to other websites or applications that are not operated by Us. If You
                click on a third party link, You will be directed to that third party’s site or application. We strongly
                advise You to review the Privacy Policy of every site or application You visit.
            </p>
            <p>
                We have no control over and assume no responsibility for the content, privacy policies or practices of
                any third party sites, applications or services.
            </p>
            <h3>Changes to this Privacy Policy</h3>
            <p>
                We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page.
            </p>
            <p>
                We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.
            </p>
            <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.
            </p>
            <h3>Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <p>
                <strong>By email:</strong> contact@whitewhale.ai
                <br />
                <strong>By visiting this page on our website:</strong> https://www.whitewhale.ai/contact
                <br />
                <strong>By phone number:</strong> +1 (587) 564-2015
            </p>
        </ScrollArea>
    </ModalCard>
);

FormPrivacyModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
};

FormPrivacyModal.defaultProps = {
    open: false,
};

export default FormPrivacyModal;
