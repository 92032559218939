import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class Section extends PureComponent {
    render() {
        const { selected, unselected, error, children } = this.props;
        return (
            <div
                className={ClassNames('Section', {
                    'Section--selected': selected,
                    'Section--unselected': unselected,
                    'Section--error': error,
                })}
            >
                {children}
            </div>
        );
    }
}

Section.propTypes = {
    unselected: PropTypes.bool,
    selected: PropTypes.bool,
    error: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Section.defaultProps = {
    unselected: false,
    selected: false,
    error: false,
};
