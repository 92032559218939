import React from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import NuggetSkin from './skin';

require('./styles.scss');

export default function DragDropNugget({
    accept,
    canDrag,
    color,
    value,
    label,
    onHover,
    onRemove,
    removable,
    removeOnSuccessfulDrop,
    removeOnDropToNothing,
    squareRight,
    isPreview,
    icon,
    containerIndex,
    type,
    canDrop,
}) {
    // handler for calling onRemove prop
    const handleRemove = () => removable && onRemove(value);

    // add drag and drop interaction
    // add hover handler
    const [, drop] = useDrop({
        accept,
        hover: (dragItem) => canDrop && onHover(dragItem.value, containerIndex),
    });
    // add drag interaction
    const [{ isDragging }, drag] = useDrag({
        type,
        item: { type, value },
        canDrag: () => canDrag,
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (!removeOnDropToNothing && !dropResult) return;
            if (!removeOnSuccessfulDrop && dropResult) return;
            if (dropResult && item.value.containerId === dropResult.containerId) return;
            handleRemove();
        },
    });

    return (
        <NuggetSkin
            forwardRef={(node) => drag(drop(node))}
            canDrag={canDrag}
            color={color}
            removable={removable}
            isDragging={isDragging}
            isPreview={isPreview}
            icon={icon}
            squareRight={squareRight}
            label={label}
            onRemove={handleRemove}
        />
    );
}

DragDropNugget.propTypes = {
    label: PropTypes.string,
    value: PropTypes.shape({}).isRequired,

    // drag props
    type: PropTypes.string,
    canDrag: PropTypes.bool,
    removable: PropTypes.bool,
    removeOnSuccessfulDrop: PropTypes.bool,
    removeOnDropToNothing: PropTypes.bool,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onRemove: PropTypes.func,
    onHover: PropTypes.func,
    containerIndex: PropTypes.number,
    canDrop: PropTypes.bool,

    // styling props
    squareRight: PropTypes.bool,
    color: PropTypes.string,
    isPreview: PropTypes.bool,
    icon: PropTypes.string,
};

DragDropNugget.defaultProps = {
    label: '',
    type: 'feature',
    canDrag: true,
    removable: true,
    removeOnSuccessfulDrop: true,
    removeOnDropToNothing: true,
    accept: '',
    onRemove: null,
    onHover: null,
    containerIndex: null,
    canDrop: false,

    squareRight: false,
    color: null,
    isPreview: false,
    icon: null,
};
