import { nanoid } from 'nanoid';

export const operations = (creators, api, additionalOperations = {}) => {
    const index = (ids) => (dispatch) => {
        dispatch(creators.indexRequest(ids));
        return api.index(ids).then(
            (models) => dispatch(creators.indexSuccess(models)),
            (errors) => dispatch(creators.indexFailure(errors)),
        );
    };

    const create = (form) => {
        const tempId = nanoid();
        return (dispatch) => {
            dispatch(creators.createRequest(form, tempId));
            return api.create(form).then(
                (model) => dispatch(creators.createSuccess(model, tempId)),
                (errors) => dispatch(creators.createFailure(errors, tempId)),
            );
        };
    };

    const destroy = (id) => (dispatch) => {
        dispatch(creators.destroyRequest(id));

        return api.destroy(id).then(
            (response) => dispatch(creators.destroySuccess(id, response)),
            (errors) => dispatch(creators.destroyFailure(id, errors)),
        );
    };

    const show = (id) => (dispatch) => {
        dispatch(creators.showRequest(id));
        return api.show(id).then(
            (model) => dispatch(creators.showSuccess(id, model)),
            (errors) => dispatch(creators.showFailure(id, errors)),
        );
    };

    const update = (id, form) => (dispatch) => {
        dispatch(creators.updateRequest(id, form));
        return api.update(id, form).then(
            (model) => dispatch(creators.updateSuccess(id, model)),
            (errors) => dispatch(creators.updateFailure(id, errors))
        );
    };

    const bulkUpdate = (form) => (dispatch) => {
        dispatch(creators.bulkUpdateRequest(form));
        return api.bulkUpdate(form).then(
            (model) => dispatch(creators.bulkUpdateSuccess(model)),
            (errors) => dispatch(creators.bulkUpdateFailure(form, errors)),
        );
    };
    const reset = () => (dispatch) => dispatch(creators.reset());
    const resetErrors = () => (dispatch) => dispatch(creators.resetErrors());

    return {
        index,
        create,
        destroy,
        show,
        update,
        reset,
        resetErrors,
        bulkUpdate,
        ...additionalOperations,
    };
};

export default operations;

export * from './reducer';
export * from './schema';
