const fixRoundingErrors = (x) => Number(x.toExponential(12));

export function stepDown(value, step = 1) {
    if (!step) return value; // eg. step = 0
    const oneOverStep = 1 / step;
    const scaleAndDecrement = fixRoundingErrors(value * oneOverStep - 1);
    const newValue = fixRoundingErrors(step * Math.ceil(scaleAndDecrement));
    return newValue;
}

export function stepUp(value, step = 1) {
    if (!step) return value; // eg. step = 0
    const oneOverStep = 1 / step;
    const scaleAndIncrement = fixRoundingErrors(value * oneOverStep + 1);
    const newValue = fixRoundingErrors(step * Math.floor(scaleAndIncrement));
    return newValue;
}

export const convertToStringNullSafe = (value) => (value ?? '').toString();
