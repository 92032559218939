import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import InputBorder from '../Border';
import BaseInput from '../Base';
import Icon from '../../Icon';

require('./styles.scss');

export default class Text extends Component {
    handleChange = (id, value) => {
        const { onChange, validations } = this.props;
        const errors = [];
        validations.forEach((validation) => validation.validate(value) || errors.push({ message: validation.error }));
        onChange(id, value, errors.length ? { ...errors[0], id } : null);
    };

    render() {
        const { id, icon, maxWidth, disabled } = this.props;
        return (
            <InputBorder {...this.props} className={ClassNames('TextInput', { 'TextInput--icon': icon || disabled })}>
                <BaseInput key={id} {...this.props} onChange={this.handleChange} maxWidth={maxWidth} />
                <Icon icon={disabled ? 'lock' : icon} size="16px" />
            </InputBorder>
        );
    }
}

Text.propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string,
    onChange: PropTypes.func,
    validations: PropTypes.array,
    maxWidth: PropTypes.number,
    setRef: PropTypes.func,
    disabled: PropTypes.bool,
};

Text.defaultProps = {
    icon: '',
    onChange: () => {},
    validations: [],
    maxWidth: undefined,
    setRef: () => {},
    disabled: false,
};
