import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputBorder from '../Border';

require('./styles.scss');

export default class TextArea extends Component {
    handleChange = (e) => {
        const { onChange, id } = this.props;
        onChange(id, e.target.value);
    };
    render() {
        const { id, rows, value, placeholder, disabled, autoexpand, inputRef, onFocus, onBlur, autoFocus } = this.props;
        return (
            <InputBorder {...this.props} className="TextArea">
                {autoexpand ? (
                    <textarea
                        className="autoExpand"
                        rows={rows}
                        ref={inputRef}
                        id={id}
                        disabled={disabled}
                        value={value}
                        onChange={this.handleChange}
                        placeholder={placeholder}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        autoFocus={autoFocus}
                    />
                ) : (
                    <textarea
                        id={id}
                        disabled={disabled}
                        value={value}
                        onChange={this.handleChange}
                        placeholder={placeholder}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        autoFocus={autoFocus}
                    />
                )}
            </InputBorder>
        );
    }
}
TextArea.propTypes = {
    id: PropTypes.string.isRequired,
    error: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    hoverable: PropTypes.bool,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    autoexpand: PropTypes.bool,
    rows: PropTypes.number,
    inputRef: PropTypes.func,
    autoFocus: PropTypes.bool,
};

TextArea.defaultProps = {
    value: '',
    error: false,
    placeholder: null,
    disabled: false,
    editable: false,
    hoverable: false,
    dark: false,
    gray: false,
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    autoexpand: false,
    rows: undefined,
    inputRef: undefined,
    autoFocus: false,
};
