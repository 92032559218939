import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InputBorder from '../Border';
import BaseInput from '../Base';
import Icon from '../../Icon';
import ToolTip from '../../ToolTip';

require('./styles.scss');

export default class Search extends Component {
    handleChange = (id, value) => {
        const { onChange, validations } = this.props;
        const errors = [];

        validations.forEach((validation) => validation.validate(value) || errors.push({ message: validation.error }));

        onChange(id, value, errors.length ? { ...errors[0], id } : null);
    };

    render() {
        const { id, icon, disabled } = this.props;
        return (
            <InputBorder {...this.props} className="SearchInput">
                <BaseInput key={id} {...this.props} onChange={this.handleChange} />
                <ToolTip tooltip="Search regex with re:<expression>" delayShow={2000}>
                    <Icon icon={disabled ? 'lock' : icon || 'search'} size="20px" />
                </ToolTip>
            </InputBorder>
        );
    }
}
Search.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    validations: PropTypes.array,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
};

Search.defaultProps = {
    onChange: () => {},
    validations: [],
    icon: '',
    disabled: false,
};
