import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

export default function NuggetBucketDropArea({ accept, onHover, canDrop, children }) {
    // an invisible drop target.
    // will be rendered on the left and right side of the nuggets

    const [, drop] = useDrop({ accept, hover: (dragItem) => canDrop && onHover(dragItem.value) });
    if (children) {
        return (
            <div ref={drop} className="NuggetBucket__dropArea">
                {children}
            </div>
        );
    }
    return <div ref={drop} className="NuggetBucket__dropArea" />;
}

NuggetBucketDropArea.propTypes = {
    // eslint-disable-next-line react/require-default-props
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onHover: PropTypes.func,
    canDrop: PropTypes.bool,
    children: PropTypes.node,
};

NuggetBucketDropArea.defaultProps = {
    canDrop: true,
    children: null,
    onHover: () => {},
};
