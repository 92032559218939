import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class InputBorder extends PureComponent {
    render() {
        const { disabled, dark, gray, error, autosize, rounded, children, className, maxWidth, setRef } = this.props;
        return (
            <div
                className={ClassNames(className, 'InputBorder', {
                    'InputBorder--disabled': disabled,
                    'InputBorder--light': !(dark || gray),
                    'InputBorder--dark': dark,
                    'InputBorder--gray': gray,
                    'InputBorder--rounded': rounded,
                    'InputBorder--error': error,
                    'InputBorder--autosize': autosize,
                })}
                style={{ maxWidth }}
                ref={setRef}
            >
                {children}
            </div>
        );
    }
}
InputBorder.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    rounded: PropTypes.bool,
    error: PropTypes.bool,
    autosize: PropTypes.bool,
    maxWidth: PropTypes.number,
    setRef: PropTypes.func,
};

InputBorder.defaultProps = {
    className: '',
    disabled: false,
    dark: false,
    gray: false,
    rounded: false,
    error: false,
    autosize: false,
    maxWidth: undefined,
    setRef: () => {},
};
