import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Icon from '../Icon';
import ToolTip from '../ToolTip';

require('./styles.scss');

export default class IconButton extends PureComponent {
    handleClick = (e) => {
        const { onClick, id } = this.props;
        e.preventDefault();
        e.stopPropagation();
        onClick(id, e);
    };
    render() {
        const {
            light,
            dark,
            red,
            yellow,
            green,
            redAlert,
            active,
            disabled,
            selected,
            large,
            mini,
            tabIndex,
            label,
            id,
            tooltip,
            tooltipPlace,
            children,
            onBlur,
            allowHtml
        } = this.props;
        const { className, ...iconProps } = this.props;
        let size = '22px';
        if (large) {
            size = '30px';
        } else if (mini) {
            size = '16px';
        }
        const button = (
            <button
                className={ClassNames('IconButton', className, {
                    'IconButton--light': light && !(dark || red || yellow || green),
                    'IconButton--dark': dark,
                    'IconButton--red': red,
                    'IconButton--redAlert': redAlert,
                    'IconButton--yellow': yellow,
                    'IconButton--green': green,
                    'IconButton--active': active,
                    'IconButton--disabled': disabled,
                    'IconButton--selected': selected,
                    'IconButton--large': large,
                    'IconButton--mini': mini,
                    'IconButton--medium': !large && !mini,
                    'IconButton--hasLabel': label,
                })}
                tabIndex={tabIndex}
                type="button"
                onClick={disabled ? null : this.handleClick}
                onBlur={onBlur}
                id={id}
            >
                <Icon {...iconProps} size={size} />
                {label ? <div className="IconButton__label">{label}</div> : null}
                {children}
            </button>
        );
        // we decided to disable the tooltip whenever active is true because active is used primary for showing that a context menu is open
        //  -- an alternative is to add a separate prop for `disableTooltip`
        return tooltip ? (
            <ToolTip delayShow={500} tooltip={tooltip} place={tooltipPlace} disable={active}>
                {button}
            </ToolTip>
        ) : (
            button
        );
    }
}

IconButton.propTypes = {
    large: PropTypes.bool,
    mini: PropTypes.bool,
    light: PropTypes.bool,
    dark: PropTypes.bool,
    red: PropTypes.bool,
    yellow: PropTypes.bool,
    green: PropTypes.bool,
    redAlert: PropTypes.bool, // makes the button red on hover
    active: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    id: PropTypes.string,
    tabIndex: PropTypes.number,
    label: PropTypes.string,
    tooltip: PropTypes.node,
    tooltipPlace: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    allowHtml: PropTypes.bool
};

IconButton.defaultProps = {
    light: true,
    dark: false,
    red: false,
    yellow: false,
    green: false,
    redAlert: false,
    large: false,
    mini: false,
    active: false,
    selected: false,
    disabled: false,
    id: '',
    tabIndex: 0,
    onBlur: () => {},
    onClick: () => {},
    label: null,
    tooltip: null,
    tooltipPlace: 'bottom',
    className: null,
    children: null,
    allowHtml: false
};
