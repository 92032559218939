import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import IconButton from '../IconButton';
import { notificationTypes } from './constants';

require('./styles.scss');

export default class Notification extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { render: true };
        if (props.autoDismiss) {
            setTimeout(() => {
                const { render } = this.state;
                if (render) this.setState({ render: false });
            }, props.autoDismiss);
        }
    }

    componentWillUnmount = () => {
        this.state = { render: false };
    };

    handleClose = () => this.setState({ render: false });

    render() {
        const { inline, message, status } = this.props;
        const { render } = this.state;
        const notificationType = notificationTypes[status] || notificationTypes.info;

        return render ? (
            <div
                className={ClassNames('Notification', `Notification--${notificationType.className}`, {
                    'Notification--inline': inline,
                    'Notification--toast': !inline,
                })}
            >
                <span className="Notification__message">{message}</span>
                {!inline && (
                    <IconButton mini icon="times" onClick={this.handleClose} {...notificationType.iconButton} />
                )}
            </div>
        ) : null;
    }
}

Notification.propTypes = {
    status: PropTypes.string,
    message: PropTypes.string,
    inline: PropTypes.bool,
    autoDismiss: PropTypes.number,
};

Notification.defaultProps = {
    status: 'info',
    message: 'Notice, you have been notified!',
    inline: false,
    autoDismiss: 0,
};
