import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InputBorder from '../Border';
import BaseInput from '../Base';
import IconButton from '../../IconButton';

import './styles.scss';

export default function Password(props) {
    const { id, maxWidth, disabled, onChange, validations } = props;

    const [visible, setVisible] = useState(false);
    const toggleVisible = () => setVisible(!visible);
    const type = visible ? 'text' : 'password';
    const icon = visible ? 'visible' : 'hidden';
    const tooltip = visible ? 'Hide Password' : 'Show Password';

    const handleChange = (_, value) => {
        const errors = [];
        validations.forEach((validation) => validation.validate(value) || errors.push({ message: validation.error }));
        onChange(id, value, errors.length ? { ...errors[0], id } : null);
    };

    return (
        <InputBorder {...props} className="TextInput PasswordInput">
            <BaseInput key={id} {...props} type={type} onChange={handleChange} maxWidth={maxWidth} />
            <IconButton
                icon={disabled ? 'lock' : icon}
                onClick={toggleVisible}
                tooltip={tooltip}
                tooltipPlace="right"
            />
        </InputBorder>
    );
}

Password.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    validations: PropTypes.array,
    maxWidth: PropTypes.number,
    setRef: PropTypes.func,
    disabled: PropTypes.bool,
};

Password.defaultProps = {
    onChange: () => {},
    validations: [],
    maxWidth: undefined,
    setRef: () => {},
    disabled: false,
};
