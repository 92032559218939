import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import SVG from 'react-inlinesvg';

import { getIcon } from './helper';

require('./styles.scss');

export default class Icon extends PureComponent {
    render() {
        const { icon, size, literal, className, pulsing, selected, height } = this.props;

        if (!icon) return null;

        return (
            <div
                className={ClassNames('Icon', className, {
                    'Icon--literal': literal,
                    'Icon--pulsing': pulsing,
                    'Icon--selected': selected,
                })}
                style={{
                    width: literal ? 'min-content' : size,
                    height: height || size,
                    minWidth: literal ? 'unset' : size,
                    minHeight: height || size,
                    fontSize: literal ? null : size,
                    lineHeight: literal ? null : size,
                }}
            >
                {literal ? icon : <SVG src={getIcon(icon)} alt={icon} uniquifyIDs />}
            </div>
        );
    }
}

Icon.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    literal: PropTypes.bool,
    pulsing: PropTypes.bool,
    selected: PropTypes.bool,
    size: PropTypes.string,
    height: PropTypes.string,
};

Icon.defaultProps = {
    className: null,
    literal: false,
    pulsing: false,
    selected: false,
    size: '12px',
    height: '',
    icon: '',
};
