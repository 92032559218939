import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class StatusBadge extends PureComponent {
    render() {
        const { success, warning, info, error, blue, secondary, pulsing, children } = this.props;
        return (
            <div
                className={ClassNames('Badge', {
                    'Badge--success': success,
                    'Badge--error': error,
                    'Badge--warning': warning,
                    'Badge--info': info,
                    'Badge--secondary': secondary,
                    'Badge--blue': blue,
                    'Badge--pulsing': pulsing,
                })}
            >
                {children}
            </div>
        );
    }
}

StatusBadge.propTypes = {
    success: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.bool,
    pulsing: PropTypes.bool,
    secondary: PropTypes.bool,
    info: PropTypes.bool,
    blue: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

StatusBadge.defaultProps = {
    success: false,
    error: false,
    warning: false,
    pulsing: false,
    secondary: false,
    info: false,
    blue: false,
};
