import React from 'react';
import PropTypes from 'prop-types';

import RadioOption from '../RadioOption';

require('./styles.scss');

const Radio = (props) => {
    const { value, id, onChange, error, dark, options, disabled } = props;

    return (
        <div className="Radio">
            {options.map((option) => {
                const optionValue = option.value === undefined ? option.label : option.value;
                return (
                    <RadioOption
                        option={option}
                        key={optionValue}
                        selected={value === optionValue}
                        id={id}
                        onSelect={disabled ? () => {} : () => onChange(id, optionValue)}
                        error={error}
                        dark={dark}
                        disabled={disabled}
                    />
                );
            })}
        </div>
    );
};

Radio.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            // eslint-disable-next-line react/forbid-prop-types
            value: PropTypes.any,
            label: PropTypes.string,
            disabled: PropTypes.bool,
        }),
    ).isRequired,
    error: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func.isRequired,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
};

Radio.defaultProps = {
    value: '',
    error: false,
    dark: false,
    disabled: false,
};

export default Radio;
