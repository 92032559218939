import pluralize from 'pluralize';
import { uniq, merge, cloneDeep } from 'lodash';
import { createActions } from 'reduxsauce';

export const actions = (modelName, additionalActions = {}) =>
    createActions(
        {
            indexRequest: [`${modelName}Ids`],
            indexSuccess: [pluralize(modelName)],
            indexFailure: ['errors'],
            createRequest: [modelName, 'tempId'],
            createSuccess: [modelName, 'tempId'],
            createFailure: ['errors', 'tempId'],
            destroyRequest: ['id'],
            destroySuccess: ['id', 'response'],
            destroyFailure: ['id', 'errors'],
            showRequest: ['id'],
            showSuccess: ['id', modelName],
            showFailure: ['id', 'errors'],
            updateRequest: ['id', modelName],
            updateSuccess: ['id', modelName],
            updateFailure: ['id', 'errors'],
            bulkUpdateRequest: [pluralize(modelName)],
            bulkUpdateSuccess: [pluralize(modelName)],
            bulkUpdateFailure: [pluralize(modelName), 'errors'],
            reset: [],
            resetErrors: [],
            ...additionalActions,
        },
        { prefix: `${pluralize(modelName).toUpperCase()}_` },
    );

// apiHandlers is a way of automaically creating a REQUEST, FAILURE and SUCCESS reducer for actions
// the handlers do nothing except manage hydrated and errors
export const apiHandlers = (additionalActions, Types, handlers = {}) => {
    const crudHandlers = {};
    const requestReducer = (state, action = {}) => {
        if (action.id && state.byId && state.byId[action.id]) {
            const byId = { ...state.byId };
            byId[action.id] = { ...byId[action.id], loading: true };
            return { ...state, errors: {}, byId };
        }
        if (action.id) {
            // if action.id exists but doesn't exist in byId, we will not create an empty object with a loading state
            return state;
        }
        return { ...state, errors: {}, loading: true };
    };
    const failureReducer = (state, action = {}) => {
        if (action.id && state.byId && state.byId[action.id]) {
            const byId = { ...state.byId };
            byId[action.id] = { ...byId[action.id], loading: false };
            return { ...state, errors: action.errors, byId };
        }
        if (action.id) {
            return { ...state, errors: action.errors };
        }
        return { ...state, loading: false, errors: action.errors };
    };
    const successReducer = (state, action = {}) => {
        if (action.id && state.byId && state.byId[action.id]) {
            const byId = { ...state.byId };
            byId[action.id] = { ...byId[action.id], loading: false };
            return { ...state, errors: {}, byId };
        }
        if (action.id) {
            return state;
        }
        return { ...state, loading: false, errors: {} };
    };
    additionalActions.forEach((action) => {
        crudHandlers[Types[`${action}_REQUEST`]] = requestReducer;
        crudHandlers[Types[`${action}_FAILURE`]] = failureReducer;
        crudHandlers[Types[`${action}_SUCCESS`]] = successReducer;
    });
    return Object.assign(crudHandlers, handlers);
};

// reducer generates the default CRUD reducers
// all reducers (except show and index) update the state immediately on the request, and
// -- if theres a failure, revert to the old object.  (a toast will be displayed to the user, as with any error)

export const makeCrudReducers = (modelName, INITIAL_STATE, model, Types) => {
    const indexRequest = (state = INITIAL_STATE) => ({ ...state, hydrated: false, loading: true, errors: {} });
    const indexFailure = (state = INITIAL_STATE, action) => ({
        ...state,
        hydrated: true,
        loading: false,
        errors: action.errors,
    });
    const indexSuccess = (state = INITIAL_STATE, action, strict = false) => {
        let allIds = [...state.allIds];
        const byId = { ...state.byId };
        allIds = uniq(
            allIds.concat(
                action[pluralize(modelName)].list.map((modelResponse, i) => {
                    let modelObj = byId[modelResponse._id || modelResponse.id] || cloneDeep(model) || {};
                    if (strict) modelObj = {};
                    byId[modelResponse._id || modelResponse.id] = { ...modelObj, ...modelResponse, listIndex: i };
                    // we do not touch hydrated and loading for the index call.
                    return modelResponse._id || modelResponse.id;
                }),
            ),
        );
        return { ...state, hydrated: true, loading: false, errors: {}, byId, allIds };
    };
    const createRequest = (state = INITIAL_STATE, action) => {
        const { tempId } = action;
        const allIds = [...state.allIds, tempId];
        const byId = { ...state.byId };
        byId[tempId] = merge(cloneDeep(model), { ...action[modelName], _id: tempId });
        return { ...state, loading: true, errors: {}, byId, allIds };
    };
    const createFailure = (state = INITIAL_STATE, action) => {
        const { tempId, errors } = action;
        const allIds = state.allIds.filter((id) => id !== tempId);
        const byId = { ...state.byId };
        delete byId[tempId];
        return { ...state, loading: false, errors, byId, allIds };
    };
    const createSuccess = (state = INITIAL_STATE, action) => {
        const { tempId } = action;
        const allIds = state.allIds.filter((id) => id !== tempId);
        const byId = { ...state.byId };
        delete byId[tempId];
        byId[action[modelName]._id || action[modelName].id] = merge(cloneDeep(model), action[modelName]);
        allIds.push(action[modelName]._id || action[modelName].id);
        return { ...state, loading: false, errors: {}, byId, allIds };
    };
    const destroyRequest = (state = INITIAL_STATE, action) => {
        const allIds = state.allIds.filter((id) => id !== action.id);
        const byId = { ...state.byId };
        const tempItem = byId[action.id];
        delete byId[action.id]; // tempItem will still point to the object
        const _tmp = { ...state._tmp };
        _tmp[action.id] = tempItem;
        return { ...state, loading: true, errors: {}, byId, allIds, _tmp };
    };
    const destroyFailure = (state = INITIAL_STATE, action) => {
        if (action.errors.statusCode === 404) {
            // an destroyFailyre will return a 404 if somebody else has deleted the object or revoked your permissions for an object
            // (if you do not have a delete access level for the object, you will see a 403 instead)
            const _tmp = { ...state._tmp };
            delete _tmp[action.id];
            return { ...state, loading: false, errors: action.errors, _tmp };
        }
        const allIds = [...state.allIds, action.id];
        const byId = { ...state.byId, [action.id]: state._tmp[action.id] };
        const _tmp = { ...state._tmp };
        delete _tmp[action.id];
        return { ...state, loading: false, errors: action.errors, byId, allIds, _tmp };
    };
    const destroySuccess = (state = INITIAL_STATE, action) => {
        const _tmp = { ...state._tmp };
        delete _tmp[action.id];
        return { ...state, loading: false, errors: {}, _tmp };
    };
    const showRequest = (state = INITIAL_STATE, action) => {
        const byId = { ...state.byId };
        let { allIds } = state;
        let modelObj = byId[action.id];
        if (!modelObj) {
            modelObj = { ...cloneDeep(model), _id: action.id };
            allIds = [...allIds, action.id];
        }
        byId[action.id] = { ...modelObj, hydrated: false, loading: true };
        return { ...state, errors: {}, allIds, byId };
    };
    const showFailure = (state = INITIAL_STATE, action) => {
        // delete the previous version of this object from redux
        //   - show failure will usually occur when somebody else has deleted the object, or modified your permissions for the object
        //   - (previously, we kept all the information that in the redux store before the showFailure)
        const byId = { ...state.byId };
        delete byId[action.id];
        const allIds = state.allIds.filter((id) => id !== action.id);
        return { ...state, errors: action.errors, allIds, byId };
    };
    const showSuccess = (state = INITIAL_STATE, action, strict = false) => {
        const allIds = [...state.allIds];
        const byId = { ...state.byId };
        let modelObj = byId[action.id] || {};
        if (strict) modelObj = {};
        byId[action.id] = merge(cloneDeep(model), {
            ...modelObj,
            ...action[modelName],
            hydrated: true,
            loading: false,
        });
        if (allIds.indexOf(action.id) === -1) {
            allIds.push(action.id);
        }
        return { ...state, errors: {}, byId, allIds };
    };
    const updateRequest = (state = INITIAL_STATE, action, strict = false) => {
        const byId = { ...state.byId };
        const tempItem = byId[action.id];
        const _tmp = { ...state._tmp };
        _tmp[action.id] = tempItem;
        let modelObj = byId[action.id] || {};
        if (strict) modelObj = cloneDeep(model);
        byId[action.id] = { ...modelObj, ...action[modelName], loading: true };
        return { ...state, errors: {}, byId, _tmp };
    };
    const updateFailure = (state = INITIAL_STATE, action) => {
        if (action.errors.statusCode === 404) {
            // an updateFailure will return a 404 if somebody else has deleted the object or revoked your permissions for an object
            // (if you have read-only permission, you will see a 403 instead)
            const byId = { ...state.byId };
            delete byId[action.id];
            const _tmp = { ...state._tmp };
            delete _tmp[action.id];
            return { ...state, errors: action.errors, byId, _tmp };
        }
        // all other status codes are handled here:
        //  -- usually a 400 error from joi validation
        // revert all changes to object
        const byId = { ...state.byId, [action.id]: { ...state._tmp[action.id], loading: false } };
        const _tmp = { ...state._tmp };
        delete _tmp[action.id];
        return { ...state, errors: action.errors, byId, _tmp };
    };
    const updateSuccess = (state = INITIAL_STATE, action) => {
        const byId = { ...state.byId, [action.id]: { ...state.byId[action.id], loading: false } };
        const _tmp = { ...state._tmp };
        delete _tmp[action.id];
        return { ...state, errors: {}, _tmp, byId };
    };

    const bulkUpdateRequest = (state = INITIAL_STATE, action, strict = false) => {
        const byId = { ...state.byId };
        const _tmp = { ...state._tmp };
        action[pluralize(modelName)].forEach((modelResponse) => {
            let modelObj = byId[modelResponse._id || modelResponse.id] || {};
            const tempItem = modelObj;
            _tmp[tempItem._id || tempItem.id] = tempItem;
            if (strict) modelObj = cloneDeep(model);
            byId[modelResponse._id || modelResponse.id] = { ...modelObj, ...modelResponse, loading: true };
        });
        return { ...state, errors: {}, byId, _tmp };
    };
    const bulkUpdateFailure = (state = INITIAL_STATE, action) => {
        const byId = { ...state.byId };
        const _tmp = { ...state._tmp };
        action[pluralize(modelName)].forEach((modelResponse) => {
            const id = modelResponse._id || modelResponse.id;
            byId[id] = { ..._tmp[id], loading: false };
            delete _tmp[id];
        });
        return { ...state, errors: action.errors, byId, _tmp };
    };
    const bulkUpdateSuccess = (state = INITIAL_STATE, action) => {
        const byId = { ...state.byId };
        const _tmp = { ...state._tmp };
        action[pluralize(modelName)].forEach((modelResponse) => {
            const id = modelResponse._id || modelResponse.id;
            byId[id] = { ...byId[id], loading: false };
            delete _tmp[id];
        });
        return { ...state, errors: {}, byId, _tmp };
    };
    const reset = () => INITIAL_STATE;
    const resetErrors = (state = INITIAL_STATE) => ({ ...state, errors: {} });

    return {
        [Types.CREATE_REQUEST]: createRequest,
        [Types.CREATE_FAILURE]: createFailure,
        [Types.CREATE_SUCCESS]: createSuccess,
        [Types.DESTROY_REQUEST]: destroyRequest,
        [Types.DESTROY_FAILURE]: destroyFailure,
        [Types.DESTROY_SUCCESS]: destroySuccess,
        [Types.SHOW_REQUEST]: showRequest,
        [Types.SHOW_FAILURE]: showFailure,
        [Types.SHOW_SUCCESS]: showSuccess,
        [Types.UPDATE_REQUEST]: updateRequest,
        [Types.UPDATE_FAILURE]: updateFailure,
        [Types.UPDATE_SUCCESS]: updateSuccess,
        [Types.BULK_UPDATE_REQUEST]: bulkUpdateRequest,
        [Types.BULK_UPDATE_FAILURE]: bulkUpdateFailure,
        [Types.BULK_UPDATE_SUCCESS]: bulkUpdateSuccess,
        [Types.INDEX_REQUEST]: indexRequest,
        [Types.INDEX_FAILURE]: indexFailure,
        [Types.INDEX_SUCCESS]: indexSuccess,
        [Types.RESET]: reset,
        [Types.RESET_ERRORS]: resetErrors,
    };
};

export default { actions, apiHandlers, makeCrudReducers };
