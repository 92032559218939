import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import moment from 'moment/moment';

import { getMonthCalendar } from './helper';

require('./styles.scss');

export default class MonthCalendar extends Component {
    isWithinRange = (date) => {
        const { min: propsMin, max: propsMax } = this.props;
        const min = propsMin ? moment(propsMin) : { isValid: () => false };
        const max = propsMax ? moment(propsMax) : { isValid: () => false };
        if (min.isValid()) return max.isValid() ? date.isBetween(min, max) : date.isAfter(min);
        return max.isValid() ? date.isBefore(max) : true;
    };

    render() {
        const { value, selectDate } = this.props;

        const date = value && moment(value).isValid() ? moment(value).startOf('day') : moment().startOf('day');
        const monthCalendar = getMonthCalendar(date);

        return (
            <div className={ClassNames('MonthCalendar')}>
                {monthCalendar.map((d) => (
                    <div
                        key={d.toISOString()}
                        className={ClassNames('MonthCalendar__day', {
                            'MonthCalendar__day--current': d.isSame(date),
                            'MonthCalendar__day--invalid': !this.isWithinRange(d),
                        })}
                        role="button"
                        onClick={() => selectDate(d)}
                        tabIndex={-1}
                    >
                        {d.date()}
                    </div>
                ))}
            </div>
        );
    }
}

MonthCalendar.propTypes = {
    value: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    selectDate: PropTypes.func,
};

MonthCalendar.defaultProps = {
    value: '',
    min: undefined,
    max: undefined,
    selectDate: () => {},
};
