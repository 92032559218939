import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';

const { Handle } = Slider;

const handle = (props) => {
    const { value, offset, vertical } = props;
    const positionStyle = vertical
        ? { bottom: `${offset - 5}%`, top: `${95 - offset}%`, left: 17 }
        : { left: `${offset - 5}%`, right: `${95 - offset}%`, bottom: 17 };
    return (
        <React.Fragment>
            <div className="Slider__label" style={positionStyle}>
                {value}
            </div>
            <Handle {...props} />
        </React.Fragment>
    );
};
handle.propTypes = {
    value: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    vertical: PropTypes.bool.isRequired,
};
export default handle;
