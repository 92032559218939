import moment from 'moment/moment';

export function getMonthCalendar(dateInput) {
    const firstDay = dateInput.clone().startOf('month').startOf('week');
    const lastDay = dateInput.clone().endOf('month').endOf('week');
    const arrayOfDays = [];
    for (const d = moment(firstDay); d.isSameOrBefore(lastDay); d.add(1, 'days')) {
        arrayOfDays.push(d.clone());
    }
    return arrayOfDays;
}
