import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import Content from './Content';

const types = {
    spinner: Spinner,
    content: Content,
};

const Loaders = (props) => {
    const { type } = props;
    const Loader = types[type];
    // const baseUrl = location.pathname; // to make loaders work in Safari
    return (
        <div className="Loader">
            <Loader {...props} />
        </div>
    );
};

Loaders.propTypes = {
    type: PropTypes.string,
    loader: PropTypes.string,
};

Loaders.defaultProps = {
    type: 'spinner',
    loader: 'scale',
};

export default Loaders;
