import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default class Switch extends PureComponent {
    handleClick = (e) => {
        const { onChange, id, value, disabled } = this.props;
        e.preventDefault();
        if (!disabled) {
            onChange(id, !value);
        }
    };

    render() {
        const { value, disabled, lg, dark, errors, gray } = this.props;
        return (
            <div
                className={ClassNames('Switch', {
                    'Switch--selected': value,
                    'Switch--notselected': !value,
                    'Switch--disabled': disabled,
                    'Switch--small': !lg,
                    'Switch--error': errors.length > 0,
                    'Switch--dark': dark,
                    'Switch--gray': gray,
                })}
                onClick={this.handleClick}
                role="button"
                tabIndex={-1}
            >
                <div className="Switch__button" />
            </div>
        );
    }
}

Switch.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.bool,
    lg: PropTypes.bool,
    disabled: PropTypes.bool,
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.shape({ message: PropTypes.string })),
    onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
    value: false,
    lg: false,
    disabled: false,
    dark: false,
    gray: false,
    errors: [],
};
