import React from 'react';
import ReactDOM from 'react-dom';

export function recursivelyModifyChildren(children, callback) {
    return React.Children.map(children, (child) => {
        if (!child || !child.props) {
            return child; // Not a react component
        }
        const childProps = callback(child) || {};
        childProps.children = recursivelyModifyChildren(child.props.children, callback);
        return React.cloneElement(child, childProps);
    });
}

const _recursivelyEvaluateFunction = (children, callback) =>
    React.Children.forEach(children, (child) => {
        if (!child || !child.props) return;
        callback(child);
        _recursivelyEvaluateFunction(child.props.children, callback);
    });

export function recursivelyReduceChildren(children, callback, initialValue) {
    let acc = initialValue;
    _recursivelyEvaluateFunction(children, (child) => {
        acc = callback(acc, child);
    });
    return acc;
}

export function componentRootAppended(id, root) {
    let componentRoot = document.getElementById(id);

    if (!componentRoot) {
        componentRoot = document.createElement('div');
        componentRoot.id = id;
        (root ? document.getElementById(root) : document.body).appendChild(componentRoot);
    }
    return componentRoot;
}

export function renderComponentAppended(component, id, root) {
    return ReactDOM.createPortal(component, componentRootAppended(id, root));
}

export function createComponentAppended(component, props, id, root) {
    const componentRoot = componentRootAppended(id, root);
    const el = document.createElement('div'); // TODO this code creates extra divs every time this function is called. look at the react tab as well, react is keeping track of dismissed notifications
    componentRoot.appendChild(el);
    ReactDOM.render(React.createElement(component, props), el);
}

export function childOfType(child, Type) {
    // checks to see if a react child is a particular component type
    return child.type === Type || child.type.prototype instanceof Type;
    // child.type === Type works in production.  child.type.prototype instanceof Type works in developement.
}

export default {
    recursivelyModifyChildren,
    recursivelyReduceChildren,
    componentRootAppended,
    createComponentAppended,
};
