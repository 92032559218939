export const notificationTypes = {
    info: {
        className: 'info',
        icon: 'info',
        iconButton: { dark: true },
    },
    success: {
        className: 'success',
        icon: 'check-circle',
        iconButton: { green: true },
    },
    warn: {
        className: 'warning',
        icon: 'exclamation-triangle',
        iconButton: { yellow: true },
    },
    error: {
        className: 'error',
        icon: 'exclamation-triangle',
        iconButton: { red: true },
    },
};
