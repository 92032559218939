import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader, { Facebook, Instagram, Code, List, BulletList } from 'react-content-loader';

import { neutral70, neutral95, neutral80, neutral50 } from '../../../styles/partials/colors';

require('./styles.scss');

const loaders = {
    facebook: (props) => <Facebook {...props} preserveAspectRatio="xMinYMin meet" />,
    instagram: (props) => <Instagram {...props} preserveAspectRatio="xMinYMin meet" />,
    code: (props) => <Code {...props} preserveAspectRatio="xMinYMin meet" />,
    list: (props) => <List {...props} preserveAspectRatio="xMinYMin meet" />,
    bulletList: (props) => <BulletList {...props} preserveAspectRatio="xMinYMin meet" />,
    contentIcon: (props) => (
        <ContentLoader {...props} preserveAspectRatio="xMinYMin meet">
            <circle cx="10" cy="10" r="10" />
            <rect x="30" y="0" rx="4" ry="4" width="170" height="20" />
        </ContentLoader>
    ),
    contentLabel: (props) => (
        <ContentLoader {...props} preserveAspectRatio="xMinYMin meet">
            <rect x="0" y="0" rx="4" ry="4" width="60" height="10" />
            <rect x="0" y="20" rx="4" ry="4" width="200" height="20" />
        </ContentLoader>
    ),
    content: (props) => (
        <ContentLoader {...props} preserveAspectRatio="none">
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
        </ContentLoader>
    ),
};

const Content = (props) => {
    const Loader = loaders[props.loader];
    // remove dark prop before passing on all other props to prevent console error
    const styles = { ...props };
    delete styles.dark;
    return props.dark ? Loader({ ...styles, backgroundColor: neutral70, foregroundColor: neutral50 }) : Loader(styles);
};

Content.propTypes = {
    loader: PropTypes.string,
    backgroundColor: PropTypes.string,
    foregroundColor: PropTypes.string,
    style: PropTypes.shape({
        height: PropTypes.string,
        width: PropTypes.string,
    }),
    contentheight: PropTypes.number,
    contentwidth: PropTypes.number,
    dark: PropTypes.bool,
};

Content.defaultProps = {
    loader: 'content',
    backgroundColor: neutral95,
    foregroundColor: neutral80,
    style: {
        height: '100%',
        width: '100%',
    },
    contentheight: 40,
    contentwidth: 200,
    dark: false,
};

export default Content;
