import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Icon from '../../Icon';

require('./styles.scss');

export default class KPIStatus extends PureComponent {
    render() {
        const { icon, label, colour } = this.props;
        return (
            <div
                className={ClassNames('KPIStatus', {
                    'KPIStatus--green': colour === 'green',
                    'KPIStatus--red': colour === 'red',
                    'KPIStatus--blue': colour === 'blue',
                })}
            >
                <Icon icon={icon} size="10px" />
                <div className="KPIStatus__label">{label}</div>
            </div>
        );
    }
}

KPIStatus.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    colour: PropTypes.string,
};

KPIStatus.defaultProps = {
    icon: '',
    label: '',
    colour: null,
};
