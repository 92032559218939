export const directions = ['left', 'right', 'bottom', 'top'];
const doubleDirections = [
    'bottom left',
    'bottom right',
    'top left',
    'top right',
    'left top',
    'left bottom',
    'right top',
    'right bottom',
];
const opposites = { bottom: 'top', top: 'bottom', left: 'right', right: 'left' };
const perpendiculars = { bottom: 'left', top: 'left', left: 'top', right: 'top' };
const multipliers = { bottom: -1, top: 1, left: 1, right: -1 };
const dimension = { right: 'width', left: 'width', top: 'height', bottom: 'height' };
const transforms = { left: 'rotate(45deg)', right: 'rotate(-135deg)', bottom: 'rotate(-45deg)', top: 'rotate(135deg)' };

function parsePosition(position) {
    if (position.includes(' ')) return position.split(' ');
    return [position, perpendiculars[position]];
}

function getStyle(position, hookBox, contentBox, hasTip) {
    const [primary, secondary] = parsePosition(position);
    let secondaryPos = multipliers[secondary] * hookBox[secondary];
    if (hasTip && hookBox[dimension[secondary]] < 60) {
        // if the hook is small and there is a tip, center the "tip" relative to the hook rect
        secondaryPos += hookBox[dimension[secondary]] / 2 - 29; // 29 = distance from context menu to "tip"
    }
    return {
        [opposites[primary]]: multipliers[opposites[primary]] * hookBox[primary] + (hasTip ? 13 : 5),
        [secondary]: secondaryPos,
    };
}

function getTipStyles(position, offset = 0) {
    if (position === 'center') return { display: 'none' };
    const [primary, secondary] = parsePosition(position);
    return {
        [opposites[primary]]: -8,
        [secondary]: 20 + offset,
        transform: transforms[primary],
    };
}

function makeBox(style, contentBox) {
    // a box here is an object with { left, right, top, bottom } (to mimic a DOMRect)
    if (!contentBox) return null;
    return {
        left: style.left || -style.right - contentBox.width,
        right: -style.right || style.left + contentBox.width,
        top: style.top || -style.bottom - contentBox.height,
        bottom: -style.bottom || style.top + contentBox.height,
    };
}

export function isOutsideScreen(box) {
    if (!box) return false;
    return (
        isOutsideScreen.left(box) ||
        isOutsideScreen.right(box) ||
        isOutsideScreen.top(box) ||
        isOutsideScreen.bottom(box)
    );
}
// return `false` if it is inside screen, and the number of pixels it is outside of the screen otherwise
isOutsideScreen.left = (box) => box.left < 10;
isOutsideScreen.right = (box) => box.right > window.innerWidth - 10;
isOutsideScreen.top = (box) => box.top < 10;
isOutsideScreen.bottom = (box) => box.bottom > window.innerHeight - 10;

const getOffset = {
    left: (box) => Math.max(10 - box.left, 0),
    right: (box) => Math.max(box.right + 10 - window.innerWidth, 0),
    top: (box) => Math.max(10 - box.top, 0),
    bottom: (box) => Math.max(box.bottom + 10 - window.innerHeight, 0),
};

function calculateDistance(box1, box2) {
    return Math.abs(box1.left - box2.left) + Math.abs(box1.top - box2.top);
}

function attemptContextMenuPlacements(positions, hookBox, contentBox, hasTip, position) {
    // given an array of positions, return the closest position in the array to the default position
    const defaultStyle = getStyle(position, hookBox, contentBox, hasTip);
    const defaultBox = makeBox(defaultStyle, contentBox);

    let bestStyle;
    let bestPosition;
    positions.reduce((acc, pos) => {
        const style = getStyle(pos, hookBox, contentBox, hasTip);
        const box = makeBox(style, contentBox);
        if (isOutsideScreen(box)) return acc;
        const difference = calculateDistance(box, defaultBox);
        if (difference < acc) {
            bestStyle = style;
            bestPosition = pos;
            return difference;
        }
        return acc;
    }, Infinity);
    if (bestStyle) return [bestStyle, bestPosition];
    return false;
}

function attemptOffsetPlacements(positions, hookBox, contentBox, hasTip) {
    // given an array of (single) positions, return the first one that is the least distance from being off screen
    const attempts = positions.map((position) => {
        const style = getStyle(position, hookBox, contentBox, hasTip);
        const box = makeBox(style, contentBox);
        if (isOutsideScreen[position](box)) return false;
        const offset = getOffset[opposites[perpendiculars[position]]](box);
        style[perpendiculars[position]] -= offset;
        return { position, style, offset };
    });
    const bestAttempt = attempts.reduce((acc, attempt) => {
        if (!attempt) return acc;
        if (!acc) return attempt;
        if (attempt.offset < acc.offset) return attempt;
        return acc;
    }, false);
    return bestAttempt && [bestAttempt.style, bestAttempt.position, bestAttempt.offset];
}

function getContextMenuStyles(position, hookBox, contentBox, hasTip, prevPosition) {
    // the initial render is in the top left of the screen
    if (!hookBox || !contentBox) return [{ top: 0, left: 0 }, 'initial'];

    // if the previous location is provided, continue rendering there as long as it is on screen
    if (directions.includes(prevPosition) || doubleDirections.includes(prevPosition)) {
        const style = getStyle(prevPosition, hookBox, contentBox, hasTip);
        if (!isOutsideScreen(makeBox(style, contentBox))) return [style, prevPosition];
    } else if (prevPosition === 'center') {
        return [{ top: '50vh', left: '50vw', transform: 'translate(-50%, -50%)' }, 'center'];
    }

    // use the position from the props if it renders on screen
    const defaultStyle = getStyle(position, hookBox, contentBox, hasTip);
    if (!isOutsideScreen(makeBox(defaultStyle, contentBox))) return [defaultStyle, position];

    // the logic that is the difference between position="right" and position="right top":
    let firstAttemptPositions = doubleDirections;
    let secondAttemptPositions = directions;
    if (directions.includes(position)) {
        // 'right' --> try 'right top' 'right  bottom' 'left top' 'left bottom'
        //             will not try 'top left', 'bottom right', etc
        firstAttemptPositions = [
            `${position} ${opposites[perpendiculars[position]]}`,
            `${opposites[position]} ${perpendiculars[position]}`,
            `${opposites[position]} ${opposites[perpendiculars[position]]}`,
        ];
        // 'right' --> try 'right' and 'left' only for second attempt
        secondAttemptPositions = [position, opposites[position]];
    }

    // try all positions, and choose the one closest to the default position
    const firstAttempt = attemptContextMenuPlacements(firstAttemptPositions, hookBox, contentBox, hasTip, position);
    if (firstAttempt) return firstAttempt;

    // try offsetting the context menu
    const secondAttempt = attemptOffsetPlacements(secondAttemptPositions, hookBox, contentBox, hasTip);
    if (secondAttempt) return secondAttempt;

    // everything else failed - display the context menu in the center of the screen
    return [{ top: '50vh', left: '50vw', transform: 'translate(-50%, -50%)' }, 'center'];
}

export function getStyles(propsPosition, hookBox, contentBox, hasTip, open, prevPosition) {
    if (!open) return [{ top: 0, left: 0 }];
    const [contextMenuStyle, position, offset] = getContextMenuStyles(
        propsPosition,
        hookBox,
        contentBox,
        hasTip,
        prevPosition,
    );
    const tipStyle = getTipStyles(position, offset);
    return [contextMenuStyle, tipStyle, position];
}
