import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import reduxReducers from './models';

const ENV = process.env.NODE_ENV;
const NO_REDUX_LOGGER = process.env.NO_REDUX_LOGGER;

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_REQUEST' || action.type === 'LOGIN_REQUEST') {
        return reduxReducers(undefined, action);
    }
    if (action.type === 'RESET_STATUS' || action.type === 'LOGIN_SUCCESS') {
        return { ...state, app: { ...state.app, statusCode: 200 } };
    }
    return reduxReducers(state, action);
};

const middleware = [thunkMiddleware];
if (!NO_REDUX_LOGGER) {
    const loggerMiddleware = createLogger();
    if (ENV !== 'production') middleware.push(loggerMiddleware);
}

export default createStore(rootReducer, applyMiddleware(...middleware));
