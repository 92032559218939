export function getIcon(icon) {
    let path = '';
    try {
        // this imports the right image depending on the icon prop
        // eslint-disable-next-line global-require, import/no-dynamic-require
        path = require(`../../../assets/icon/${icon}.svg`).default;
    } catch (e) {
        // svg not found
    }
    return path;
}
