import React from 'react';
import PropTypes from 'prop-types';

require('./styles.scss');

export default function ContextSubMenu(props) {
    const { children, title, outerTitle } = props;
    return (
        <React.Fragment key={title}>
            {outerTitle && <div className="ContextMenu__subMenuHeading"> {title} </div>}
            <div className="ContextMenu__subMenu">
                {title && !outerTitle && <div className="ContextMenu__subMenuHeading"> {title} </div>}
                {children}
            </div>
        </React.Fragment>
    );
}
ContextSubMenu.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    outerTitle: PropTypes.bool,
};

ContextSubMenu.defaultProps = {
    title: '',
    outerTitle: false,
};
