import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';

import './styles.scss';

export default function SidePanelButton(props) {
    const { onClick, icon, id, style } = props;
    return (
        <button type="button" className="SidePanelButton" onClick={onClick} id={id} style={style}>
            <Icon icon={icon} size="18px" />
        </button>
    );
}

SidePanelButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    style: PropTypes.shape({}),
    icon: PropTypes.string,
    id: PropTypes.string,
};

SidePanelButton.defaultProps = {
    style: {},
    icon: 'angle-right',
    id: null,
};
