import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Checkbox from '../Checkbox';

require('./styles.scss');

export default function MultiCheckbox({ id, value, onChange, options, light, dark, gray, error, disabled }) {
    const parsedValue = !value || value === '' ? options.map(() => false) : value;
    while (parsedValue.length < options.length) parsedValue.push(false);
    while (parsedValue.length > options.length) parsedValue.pop();

    const handleChange = (checkboxId, checkboxValue) => {
        const nextValue = [...parsedValue];
        const i = options.findIndex((option) => option.value === checkboxId);
        nextValue[i] = checkboxValue;
        onChange(id, nextValue);
    };

    return (
        <div
            className={ClassNames('MultiCheckbox', {
                'MultiCheckbox--error': error,
                'MultiCheckbox--dark': dark,
                'MultiCheckbox--disabled': disabled,
            })}
        >
            {options.map((option, i) => (
                <div className="MultiCheckbox__row" key={option.value}>
                    <Checkbox
                        id={option.value}
                        value={parsedValue[i]}
                        onChange={handleChange}
                        light={light}
                        dark={dark}
                        gray={gray}
                        error={error}
                    />
                    <label htmlFor={option.value}>{option.label}</label>
                </div>
            ))}
        </div>
    );
}
MultiCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    value: PropTypes.arrayOf(PropTypes.string),
    dark: PropTypes.bool,
    gray: PropTypes.bool,
    light: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
};

MultiCheckbox.defaultProps = {
    onChange: () => {},
    value: [],
    dark: false,
    gray: false,
    light: false,
    options: [],
    error: false,
    disabled: false,
};
