import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import BaseInput from '../Base';
import InputBorder from '../Border';
import IconButton from '../../IconButton';
import { stepDown, stepUp, convertToStringNullSafe } from './helpers';

require('./styles.scss');

export default class NumberInput extends Component {
    constructor(props) {
        super(props);
        this.state = { textValue: convertToStringNullSafe(props.value) };
    }
    componentDidUpdate(prevProps) {
        const { value } = this.props;
        const { textValue } = this.state;
        if (prevProps.value !== value && textValue !== convertToStringNullSafe(value)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ textValue: convertToStringNullSafe(value) });
        }
    }
    handleStepDown = () => {
        const { id, step, min, max, value, disabled, onChange } = this.props;
        if (disabled) return;
        if (min === null || value > min) {
            const newValue = stepDown(value, step);
            const nextValue = max !== null && newValue > max ? max : newValue;
            this.setState({ textValue: nextValue.toString() });
            onChange(id, nextValue);
        }
    };
    handleStepUp = () => {
        const { id, step, min, max, value, disabled, onChange } = this.props;
        if (disabled) return;
        if (max === null || value < max) {
            const newValue = stepUp(value, step);
            const nextValue = min !== null && newValue < min ? min : newValue;
            this.setState({ textValue: nextValue.toString() });
            onChange(id, nextValue);
        }
    };
    handleTextChange = (_, textValue) => {
        const { id, onChange, validations } = this.props;
        let { max, min } = this.props;
        const value = numeral(textValue);
        max = max === null ? value.value() + 1 : max;
        min = min === null ? value.value() - 1 : min;
        this.setState({ textValue });
        const errors = [];
        validations.forEach((validation) => validation.validate(value) || errors.push({ message: validation.error }));
        const zeroEquivalentValues = ['-', '-0', '-0.'];
        if (value.value() <= max && value.value() >= min && !zeroEquivalentValues.includes(value.input())) {
            onChange(id, value.value(), errors.length ? { ...errors[0], id } : null);
        }
    };
    handleTextBlur = () => {
        const { value, onBlur } = this.props;
        this.setState({ textValue: convertToStringNullSafe(value) });
        if (onBlur) onBlur();
    };

    render() {
        const { value, disabled, light, gray, dark, error: propsError } = this.props;
        const { textValue } = this.state;
        const error =
            propsError ||
            (textValue !== '' &&
                (numeral(textValue).value() !== value ||
                    (!numeral(textValue).value() && numeral(textValue).value() !== 0)));
        return (
            <InputBorder {...this.props} className="NumberInput" error={error}>
                <IconButton
                    mini
                    icon="triangle-left"
                    onClick={this.handleStepDown}
                    disabled={disabled}
                    light={light}
                    dark={dark}
                    gray={gray}
                    tabIndex={-1}
                    id="left"
                />
                <BaseInput
                    {...this.props}
                    type="text"
                    value={textValue}
                    onChange={this.handleTextChange}
                    onBlur={this.handleTextBlur}
                    inputMode="decimal" // opens number keyboard with decimal on mobile
                />
                <IconButton
                    mini
                    icon="triangle-right"
                    onClick={this.handleStepUp}
                    disabled={disabled}
                    light={light}
                    dark={dark}
                    gray={gray}
                    tabIndex={-1}
                    id="right"
                />
            </InputBorder>
        );
    }
}

NumberInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.bool,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    onBlur: PropTypes.func.isRequired,
    error: PropTypes.bool,
    validations: PropTypes.array,
    disabled: PropTypes.bool,
    autosize: PropTypes.bool,
    light: PropTypes.bool, // eslint-disable-line react/require-default-props
    gray: PropTypes.bool, // eslint-disable-line react/require-default-props
    dark: PropTypes.bool, // eslint-disable-line react/require-default-props
};

NumberInput.defaultProps = {
    value: 0,
    sm: false,
    min: null,
    max: null,
    step: 1,
    onChange: () => {},
    error: false,
    validations: [],
    disabled: false,
    autosize: false,
};
