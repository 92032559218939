import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { useDrop } from 'react-dnd';
import { nanoid } from 'nanoid';

import { usePreviewItems } from './helpers';
import Section from '../Section';
import Nugget from '../Nugget/dragdrop';
import NuggetBucketDropArea from './droparea';

require('./styles.scss');

export default function NuggetBucket({
    accept,
    validation,
    id,
    nuggets,
    onChange,
    vertical,
    placeholder,
    isStatic,
    removeOnSuccessfulDrop,
    removeOnDropToNothing,
}) {
    // `items` are nuggets with additional data about their container
    const items = useMemo(
        () => nuggets.map((nugget) => ({ nugget, containerId: id, id: nanoid(), type: nugget.type })),
        [nuggets, id],
    );

    // previewItems will be displayed when previewing next hover state
    const [previewItems, setPreviewItem, tempItem] = usePreviewItems(id, items, { isStatic });

    // handlers for drop areas
    const handleHoverBefore = useCallback((dragItem) => setPreviewItem(dragItem, 0), [setPreviewItem]);
    const handleHoverAfter = useCallback((dragItem) => setPreviewItem(dragItem, items.length), [
        setPreviewItem,
        items.length,
    ]);

    // handler for removing a nugget // TODO: use f(index) instead of f(item)
    const removeNugget = (item) => {
        const nextNuggets = nuggets.filter((nugget) => nugget !== item.nugget);
        onChange(
            id,
            nextNuggets.map((nugget) => nugget.value),
        );
    };

    // add drag and drop interaction
    const [{ isOver, canDrop, hoveredItem }, drop] = useDrop({
        accept,
        canDrop: (dragItem) =>
            !isStatic &&
            validation(
                items.filter((item) => item.id !== dragItem.value.id).map((item) => item.nugget.value),
                dragItem.value.nugget.value,
            ),
        collect: (monitor) => ({
            hoveredItem: monitor.getItem() && monitor.getItem().value,
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        drop: () => {
            onChange(
                id,
                previewItems.map((item) => item.nugget.value),
            );
            return { containerId: id };
        },
    });

    // only showing previewItems when
    //    - hovering over bucket
    //    - bucket allows reordering (!isStatic)
    // (also checking tempItem === hoveredItem to prevent it rendering an old preview item on first render)
    const itemsToRender =
        !isStatic && isOver && canDrop && tempItem && tempItem.id === hoveredItem.id ? previewItems : items;

    return (
        <div
            ref={drop}
            className={ClassNames('NuggetBucket', {
                'NuggetBucket--vertical': vertical,
                'NuggetBucket--horizontal': !vertical,
                'NuggetBucket--hover': isOver,
            })}
        >
            <Section selected={canDrop} unselected={!canDrop}>
                <NuggetBucketDropArea canDrop={canDrop} accept={accept} onHover={handleHoverBefore} />
                <div className="NuggetBucket__content">
                    {itemsToRender.map((item, index) => (
                        <Nugget
                            removable={!isStatic}
                            removeOnSuccessfulDrop={!isStatic && removeOnSuccessfulDrop}
                            removeOnDropToNothing={!isStatic && removeOnDropToNothing}
                            {...item.nugget}
                            key={item.id}
                            accept={accept}
                            canDrop={canDrop}
                            containerIndex={index}
                            onHover={setPreviewItem}
                            onRemove={removeNugget}
                            value={item}
                            isPreview={item.containerId !== id}
                        />
                    ))}
                </div>
                {itemsToRender.length === 0 && <div className="NuggetBucket__placeholder">{placeholder}</div>}
                <NuggetBucketDropArea canDrop={canDrop} accept={accept} onHover={handleHoverAfter} />
            </Section>
        </div>
    );
}

NuggetBucket.propTypes = {
    id: PropTypes.string.isRequired,
    nuggets: PropTypes.arrayOf(PropTypes.shape({})),
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    validation: PropTypes.func,

    isStatic: PropTypes.bool,
    removeOnSuccessfulDrop: PropTypes.bool,
    removeOnDropToNothing: PropTypes.bool,
    vertical: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

NuggetBucket.defaultProps = {
    nuggets: [],
    accept: 'feature',

    validation: () => true,
    removeOnSuccessfulDrop: true,
    removeOnDropToNothing: true,
    isStatic: false,
    // isStatic: true is a shorthand for
    // { removeOnSuccessfulDrop: false, removeOnDropToNothing: false }
    // and validation: () => false
    // and set removable: false on every nugget

    vertical: false,
    placeholder: '',
    onChange: () => {},
};
