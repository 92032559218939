import { createReducer } from 'reduxsauce';
import { makeCrudReducers, initialState, apiHandlers } from '../../../../models/base';
import { Types as projectTypes, transferAssetsSuccess } from '../../../Projects/_models/projects/reducers';
import { SocketTypes } from '../../../App/Socket/socket_types';
import Schema from './schema';
import { Types as ModelTypes } from '../models/reducers';
import { Types } from './types';

const INITIAL_STATE = initialState();
// ---------------- ACTION HANDLERS ----------------
const cancelRequest = (state) => state;
const cancelSuccess = (state) => state; // State is being handled by socket actions
const cancelFailure = (state) => ({ ...state, errors: {} });
// ---------------- CREATE ACTIONS ----------------

// ---------------- CREATE REDUCERS ----------------

const crudReducers = makeCrudReducers('modelRun', INITIAL_STATE, Schema, Types);

const updateSuccess = (state, action) => {
    if (state.byId[action.id]) {
        return crudReducers[Types.UPDATE_SUCCESS](crudReducers[Types.UPDATE_REQUEST](state, action), action);
    }
    return crudReducers[Types.SHOW_SUCCESS](crudReducers[Types.SHOW_REQUEST](state, action), action);
};

const modelRunsSuccess = (state, { modelRuns }) => {
    const byId = { ...state.byId };
    const allIds = [...state.allIds];
    modelRuns.forEach((modelRun) => {
        if (!byId[modelRun._id]) {
            byId[modelRun._id] = modelRun;
            allIds.push(modelRun._id);
        }
    });
    return { ...state, byId, allIds, hydrated: true, loading: false };
};

const additionalReducers = apiHandlers([], Types, {
    [Types.UPDATE_SUCCESS]: updateSuccess,
    [SocketTypes.MODEL_RUN]: updateSuccess,
    [ModelTypes.GET_RUNS_SUCCESS]: modelRunsSuccess,
    [Types.CANCEL_REQUEST]: cancelRequest,
    [Types.CANCEL_SUCCESS]: cancelSuccess,
    [Types.CANCEL_FAILURE]: cancelFailure,
    [projectTypes.CLEAR_RESOURCES]: crudReducers[Types.RESET],
    [projectTypes.TRANSFER_ASSETS_SUCCESS]: transferAssetsSuccess('modelRuns'),
});
export default createReducer(INITIAL_STATE, { ...crudReducers, ...additionalReducers });
