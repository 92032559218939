import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import IconButton from '../IconButton';
import { notificationTypes } from './constants';
import Icon from '../Icon';
import Button from '../Button';
import ToolTip from '../ToolTip';

require('./styles.scss');

export default function NotificationPopUp(props) {
    const {
        type,
        title,
        subTitle,
        description,
        primaryButtonLabel,
        primaryButtonOnClick,
        secondaryButtonLabel,
        secondaryButtonOnClick,
        closeButton,
        autoDismiss,
    } = props;

    const notificationType = notificationTypes[type] || notificationTypes.info;

    const [displayNotification, setDisplayNotification] = useState(true);

    if (autoDismiss) {
        setTimeout(() => {
            setDisplayNotification(false);
        }, autoDismiss);
    }

    const onClose = () => {
        setDisplayNotification(false);
    };

    return (
        displayNotification && (
            <div className={ClassNames('NotificationPopUp', `NotificationPopUp--${notificationType.className}`)}>
                <Icon icon={notificationType.icon} className="NotificationPopUp__icon" size="25px" />
                <div className="NotificationPopUp__text">
                    <div className="NotificationPopUp__title">{title}</div>
                    {subTitle && <div className="NotificationPopUp__subTitle">{subTitle}</div>}
                </div>
                {secondaryButtonLabel && (
                    <Button
                        className="NotificationPopUp__secondaryButton"
                        onClick={() => {
                            secondaryButtonOnClick();
                            onClose();
                        }}
                        small
                        dark
                    >
                        {secondaryButtonLabel}
                    </Button>
                )}
                {primaryButtonLabel && (
                    <Button
                        className="NotificationPopUp__primaryButton"
                        onClick={() => {
                            primaryButtonOnClick();
                            onClose();
                        }}
                        small
                        blue
                    >
                        {primaryButtonLabel}
                    </Button>
                )}
                {description && (
                    <ToolTip className="NotificationPopUp__description" tooltip={description}>
                        <Icon icon="info" size="16px" />
                    </ToolTip>
                )}
                {closeButton && (
                    <IconButton
                        className="NotificationPopUp__closeButton"
                        mini
                        icon="times"
                        onClick={() => setDisplayNotification(false)}
                    />
                )}
            </div>
        )
    );
}

NotificationPopUp.propTypes = {
    type: PropTypes.string.isRequired, // wht kind it is: info, error, warning, success, or info
    title: PropTypes.string.isRequired, // title
    subTitle: PropTypes.string, // sub title
    description: PropTypes.string, // description
    primaryButtonLabel: PropTypes.string,
    primaryButtonOnClick: PropTypes.func,
    secondaryButtonLabel: PropTypes.string,
    secondaryButtonOnClick: PropTypes.func,
    closeButton: PropTypes.bool,
    autoDismiss: PropTypes.number,
};

NotificationPopUp.defaultProps = {
    subTitle: '',
    description: '',
    primaryButtonLabel: '',
    primaryButtonOnClick: () => {},
    secondaryButtonLabel: '',
    secondaryButtonOnClick: () => {},
    closeButton: true,
    autoDismiss: 0,
};
