import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

require('./styles.scss');

export default function TabHeader(props) {
    const { tabs, tabIndex, className, onSelect } = props;

    return (
        <header className={ClassNames('TabHeader', className)}>
            {tabs.map((tab, i) => (
                <span
                    className={ClassNames('Tabs__tab', {
                        'Tabs__tab--active': i === tabIndex,
                    })}
                    key={tab.label}
                    onClick={() => i !== tabIndex && onSelect(i)}
                    role="button"
                    tabIndex={0}
                >
                    {tab.label}
                </span>
            ))}
        </header>
    );
}

TabHeader.propTypes = {
    className: PropTypes.string,
    tabIndex: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
        }),
    ).isRequired,
};

TabHeader.defaultProps = {
    className: '',
};
