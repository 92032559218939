import PublicPagesApi from './api';
import { Actions } from './reducers';

const show = (query) => (dispatch) => {
    dispatch(Actions.showRequest(query));
    return PublicPagesApi.show(query).then(
        (response) => dispatch(Actions.showSuccess(response)),
        (error) => dispatch(Actions.showFailure(error)),
    );
};

export default {
    show,
};
