import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider, { Range } from 'rc-slider';
import TextInput from '../Text';
import Handle from './Handle';

require('rc-slider/assets/index.css');
require('./styles.scss');

export default class SliderInput extends Component {
    constructor(props) {
        super(props);
        const { value } = props;
        this.state = { value, textValue: value };
    }
    handleSliderChange = (value) => {
        this.setState({ value, textValue: value.toString() });
    };
    handleAfterChange = (newValue) => {
        const { value, id, onChange } = this.props;
        if (newValue !== value) {
            onChange(id, newValue);
        }
    };
    handleTextChange = (_, value) => {
        const { min, max, id, onChange } = this.props;
        const numberValue = Number(value);
        if (numberValue <= max && numberValue >= min) {
            this.setState({ value: numberValue, textValue: value });
            onChange(id, numberValue);
        } else {
            this.setState({ textValue: value });
        }
    };
    handleTextBlur = () => {
        const { value } = this.state;
        this.setState({ textValue: value.toString() });
    };

    render() {
        const { id, inlineLabel } = this.props;
        const { value, textValue } = this.state;
        const SliderComponent = Array.isArray(value) ? Range : Slider;
        return (
            <div className={`Slider${inlineLabel ? ' Slider--inline' : ''}`}>
                {inlineLabel && (
                    <TextInput
                        id={`${id}-label`}
                        sm
                        autosize
                        value={textValue}
                        error={Number(textValue) !== value}
                        onChange={this.handleTextChange}
                        onBlur={this.handleTextBlur}
                    />
                )}
                <SliderComponent
                    {...this.props}
                    value={value}
                    onChange={this.handleSliderChange}
                    onAfterChange={this.handleAfterChange}
                    handle={inlineLabel ? undefined : Handle}
                />
            </div>
        );
    }
}
SliderInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    min: PropTypes.number,
    max: PropTypes.number,
    inlineLabel: PropTypes.bool,
};
SliderInput.defaultProps = {
    value: 0,
    min: 0,
    max: 100,
    inlineLabel: false,
};
