import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import moment from 'moment/moment';

import Icon from '../../Icon';

require('./styles.scss');

export default class TimelineItem extends Component {
    constructor(props) {
        super(props);
        this.state = { expanded: false };
    }

    toggleExpanded = () => {
        const { onExpand } = this.props;
        const { expanded } = this.state;
        onExpand();
        this.setState({ expanded: !expanded });
    };

    render() {
        const { align, drawLine, time, title, status, extraDiv, children } = this.props;
        const { expanded } = this.state;
        const expandable = !!children;

        let iconType = '';
        if (expandable) {
            iconType = expanded ? 'angle-up' : 'angle-down';
        }

        const Bullet = (
            <div
                className={ClassNames('TimelineItem__bullet', `TimelineItem__bullet--${status}`, {
                    'TimelineItem__bullet--expandable': expandable,
                })}
            >
                <Icon icon={iconType} size="20px" />
            </div>
        );

        const Details = (
            <div className="TimelineItem__details">
                <div className="TimeLineItem__mainDetails" onClick={this.toggleExpanded} role="button" tabIndex={0}>
                    <div className="TimelineItem__details_title"> {title} </div>
                    <div className="TimelineItem__details_date">
                        {moment.utc(time).local().format('YYYY-MM-DD, hh:mm:ss A')}
                    </div>
                    {extraDiv}
                </div>
                <div
                    className={`TimelineItem__dropdown TimelineItem__dropdown--${
                        expanded && expandable ? 'expanded' : 'collapsed'
                    }`}
                >
                    {children}
                </div>
            </div>
        );

        return (
            <div
                className={`TimelineItem TimelineItem--${align} TimelineItem--${
                    expanded && expandable ? 'expanded' : 'collapsed'
                }`}
            >
                {align === 'right' ? Bullet : Details}
                {drawLine ? (
                    <div
                        className={`Timeline__line Timeline__line--${align} Timeline__line--${
                            expanded && expandable ? 'expanded' : 'collapsed'
                        }`}
                    />
                ) : null}
                {align === 'right' ? Details : Bullet}
            </div>
        );
    }
}

TimelineItem.propTypes = {
    align: PropTypes.string,
    children: PropTypes.node,
    extraDiv: PropTypes.node,
    drawLine: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    time: PropTypes.string,
    title: PropTypes.string,
    onExpand: PropTypes.func,
};

TimelineItem.defaultProps = {
    align: 'left',
    children: null,
    extraDiv: null,
    onExpand: () => {},
    time: null,
    title: '',
};
