import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import RadioOption from '../RadioOption';

require('./styles.scss');

const ScaleInput = (props) => {
    const { id, onChange, value, error, dark, min, max, minLabel, maxLabel, disabled } = props;
    const [scaleValue, setScaleValue] = useState(value);

    const scaleOptions = Array.from({ length: Math.round(max - min) + 1 }, (_, i) => Math.round(min) + i).map((i) => ({
        value: i,
    }));

    const selectItem = (event) => {
        const newValue = parseInt(event.target.value);
        setScaleValue(newValue);
        onChange(id, newValue);
    };

    return (
        <div className={ClassNames('ScaleInput', { 'ScaleInput--dark': dark })}>
            <div className="ScaleInput__scale">
                {scaleOptions.map((option) => (
                    <RadioOption
                        key={option.value}
                        option={option}
                        selected={option.value === scaleValue}
                        id={id}
                        onSelect={selectItem}
                        error={error}
                        dark={dark}
                        disabled={disabled}
                    />
                ))}
            </div>
            <div className="ScaleInput__labels">
                <span className="ScaleInput__label">{minLabel || scaleOptions[0].value}</span>
                <span className="ScaleInput__label">{maxLabel || scaleOptions[scaleOptions.length - 1].value}</span>
            </div>
        </div>
    );
};
ScaleInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    minLabel: PropTypes.string,
    maxLabel: PropTypes.string,
    error: PropTypes.bool,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
};
ScaleInput.defaultProps = {
    value: null,
    min: 1,
    max: 10,
    minLabel: null,
    maxLabel: null,
    error: false,
    dark: false,
    disabled: false,
};

export default ScaleInput;
