const packageMetadata = require('../package.json');

const port = '8080';
const access = '9001';
const socket = '9001';

export default {
    env: process.env.NODE_ENV,
    mobi: process.env.MOBI,
    app: {
        version: packageMetadata.version,
        internalApiHost: `${location.origin.replace(port, access)}/v1/`, // eslint-disable-line no-restricted-globals
        internalSocketHost:
            process.env.NODE_ENV === 'development'
                ? `ws://${location.host.replace(port, socket)}/` // eslint-disable-line no-restricted-globals
                : `wss://${location.host.replace(port, socket)}/`, // eslint-disable-line no-restricted-globals
    },
    msal: {
        redir:
            process.env.NODE_ENV === 'development'
                ? `http://${location.host}/login` // eslint-disable-line no-restricted-globals
                : `https://${location.host}/login`, // eslint-disable-line no-restricted-globals
    },
};
