import React from 'react';
import moment from 'moment/moment';

export function getCalendarPrecision(precision) {
    return ['hour', 'minute', 'second', 'millisecond'].includes(precision) ? 'day' : precision;
}

const precisionOptions = {
    year: {
        stepSlow: [1, 'years'],
        // stepFast: [10, 'years'],
        format: 'YYYY',
        title: (date) => date.format('YYYY'),
        header: null,
    },
    month: {
        stepSlow: [1, 'years'],
        // stepFast: [10, 'years'],
        title: (date) => date.format('YYYY'),
        format: 'MMMM YYYY',
        header: null,
    },
    day: {
        stepSlow: [1, 'months'],
        stepFast: [1, 'years'],
        title: (date) => date.format('MMMM YYYY'),
        format: 'll',
        header: moment.weekdaysMin().map((wd) => <div key={wd}>{wd}</div>),
    },
};

export function getPrecisionOptions(precision = 'day') {
    return precisionOptions[getCalendarPrecision(precision)];
}

// -------- Original Week Headers:
//
//          This format accounts for the fact that calenders may start of different days of the week depending on convention of location
//                              ie. start of week on Sunday or Monday
//
// header: (date) => {
//     const monthCalendar = getMonthCalendar(date);
//     return (
//         <div className="DateInput__DoW">
//             {monthCalendar.slice(0, 7).map((d) => (
//                 <div key={d.day()}>{d.format('dd')}</div>
//             ))}
//         </div>
//     );
// },
