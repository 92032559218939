import store from '../../../../store';
import { showNotificationPopUp } from '../../../../components/shared/NotificationPopUp/helpers';

// a function to handle actions for various errors
// returns an object, which will be passed to redux
export default function handleApiError(errorResponse, obj = {}) {
    // @hapi/boom error payloads have { message, statusCode, error }.
    // We have extended this to have { attributes }, which contains additional information about the error (usually from joi)
    const { message, attributes } = errorResponse;
    let { statusCode } = errorResponse;
    statusCode = statusCode || 550; // default status code is 550 (our backend is likely down altogether, including our load balancer)

    // handle different status codes
    if (statusCode === 400) {
        // 400 bad request (generally Joi validation failure)
        // Joi validation failures should be displayed as errors on the input component
    } else if ([404, 403, 401].includes(statusCode)) {
        // 404: not found
        // 401: user is not logged in (this should only happen on login page)
        // 403: user does not have permission to perform action
        showNotificationPopUp({ type: 'warn', title: `${message}`, autoDismiss: 7000 });
    } else if (statusCode === 440) {
        // User has an expired token, or does not have a valid token.
        localStorage.removeItem('accessToken');
        // render the error page so we can redirect to login
        store.dispatch({ type: 'SERVER_ERROR', statusCode });
        showNotificationPopUp({ type: 'warn', title: `${message}`, autoDismiss: 7000 });
    } else if (statusCode > 400) {
        // other errors (typically 500, 502, and 550 errors)
        // dispatching a `SERVER_ERROR` action will cause the app to render the error page
        store.dispatch({ type: 'SERVER_ERROR', statusCode });
        // (we used to treat the dev environment differently than the production environment)
    }

    // calculate the `errors` object to be passed down to the redux store
    const errors = { statusCode };
    if (attributes && attributes.errors) {
        // attributes.errors is where we store the 'errors' returned from Joi
        Object.keys(attributes.errors).forEach((key) => {
            errors[key] = attributes.errors[key] && attributes.errors[key].errors;
        });
    } else {
        Object.keys(obj).forEach((key) => {
            errors[key] = [{ message: 'Internal Server Error' }];
        });
    }
    return errors;
}
