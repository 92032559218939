import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import IconButton from '../../IconButton';
import ContextMenu from '../../ContextMenu';
import TextInput from '../Text';

import MonthCalendar from './MonthCalendar';
import YearCalendar from './YearCalendar';
import { getPrecisionOptions, getCalendarPrecision } from './helpers';

require('./styles.scss');

const calendarTypes = {
    day: MonthCalendar,
    month: YearCalendar,
};

export default class DateInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            textValue: moment(props.value).isValid()
                ? moment(props.value).format(getPrecisionOptions(props.precision).format)
                : '',
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.value !== prevState.value) {
        return { textValue: nextProps.value };
      }
      return null;
    }
    
    isWithinRange = (date) => {
        const { min: propsMin, max: propsMax } = this.props;
        const min = propsMin ? moment(propsMin) : { isValid: () => false };
        const max = propsMax ? moment(propsMax) : { isValid: () => false };
        // don't allow nonsense dates (pgsql gives an error in some extreme cases)
        if (!date.isBetween(moment('1000-01-1'), moment('2200-01-1'))) return false;
        if (min.isValid()) return max.isValid() ? date.isBetween(min, max) : date.isAfter(min);
        return max.isValid() ? date.isBefore(max) : true;
    };
    validate = (id, value) => {
        const { validations } = this.props;
        const errors = [];
        validations.forEach((validation) => validation.validate(value) || errors.push({ message: validation.error }));
        return errors.length ? { ...errors[0], id } : null;
    };
    handleDatePickerChange = (date) => {
        const { onChange, id, precision } = this.props;

        if (this.isWithinRange(date)) {
            this.setState({ textValue: date.format(getPrecisionOptions(precision).format) });
            onChange(id, date.toISOString().split('T')[0], this.validate(id, date.toISOString().split('T')[0]));
        } else {
            this.setState({});
        }
    };
    handleTextChange = (_, value) => {
        const { onChange, id } = this.props;
        this.setState({ textValue: value, open: true });
        const d = moment(value);
        if (d.isValid() && this.isWithinRange(d)) {
            onChange(id, d.toISOString().split('T')[0], this.validate(id, d.toISOString().split('T')[0]));
        } else if (!d.isValid()) {
            onChange(id, d.toISOString().split('T')[0], this.validate(id, d.toISOString().split('T')[0]));
        }
    };
    handleTextBlur = () => {
        const { value, precision } = this.props;
        const textValue =
            value && moment(value).isValid() ? moment(value).format(getPrecisionOptions(precision).format) : value;
        this.setState({ textValue });
    };
    handleKeyPress = (e) => {
        const { onKeyPress } = this.props;
        if (e.key === 'Enter') this.setState({ open: false });
        onKeyPress(e);
    };
    toggleOpen = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    handleCalendarScrollForward = (date, stepOptions) => {
        const { max, precision } = this.props;
        let newDate = date.add(...stepOptions);
        if (max) {
            newDate = date.isBefore(max) ? newDate : moment(max).subtract(1, precision);
        }
        this.handleDatePickerChange(newDate);
    };

    handleCalendarScrollBack = (date, stepOptions) => {
        const { min, precision } = this.props;
        let newDate = date.subtract(...stepOptions);
        if (min) {
            newDate = date.isAfter(min) ? newDate : moment(min).add(1, precision);
        }
        this.handleDatePickerChange(newDate);
    };

    render() {
        const { id, value, placeholder, icon, error, disabled, gray, dark, rounded, sm, precision } = this.props;
        const { min, max, validations } = this.props;
        const { open, textValue } = this.state;

        const precisionOptions = getPrecisionOptions(precision);
        const calendarPrecision = getCalendarPrecision(precision);
        const Calendar = calendarTypes[calendarPrecision];

        const date =
            value && moment(value).isValid()
                ? moment(value).startOf(calendarPrecision)
                : moment().startOf(calendarPrecision);

        // ------------------  Arrows for scrolling through Calendar  ----------------------
        const previousArrows = (
            <div>
                {precisionOptions.stepFast && (
                    <IconButton
                        dark
                        icon="double-left"
                        onClick={() => this.handleCalendarScrollBack(date, precisionOptions.stepFast)}
                    />
                )}
                <IconButton
                    dark
                    icon="angle-left"
                    onClick={() => this.handleCalendarScrollBack(date, precisionOptions.stepSlow)}
                />
            </div>
        );
        const nextArrows = (
            <div>
                <IconButton
                    dark
                    icon="angle-right"
                    onClick={() => this.handleCalendarScrollForward(date, precisionOptions.stepSlow)}
                />
                {precisionOptions.stepFast && (
                    <IconButton
                        dark
                        icon="double-right"
                        onClick={() => this.handleCalendarScrollForward(date, precisionOptions.stepFast)}
                    />
                )}
            </div>
        );

        // ------------------  contextMenu for date selection  ----------------------
        const dateContextMenu = (
            <ContextMenu
                className="DateInput__menu"
                open={open}
                onClickOut={this.toggleOpen}
                initialFocusRef={this.initialFocusDiv}
            >
                <header>
                    <div className="DateInput__Title">
                        {previousArrows}
                        <span> {precisionOptions.title(date)} </span>
                        {nextArrows}
                    </div>
                    {precisionOptions.header && <div className="DateInput__Headers">{precisionOptions.header}</div>}
                </header>
                {Calendar && (
                    <Calendar
                        value={value}
                        min={min}
                        max={max}
                        validations={validations}
                        id={id}
                        selectDate={(input) => this.handleDatePickerChange(input)}
                    />
                )}
            </ContextMenu>
        );

        return (
            <div className="DateInput">
                <div
                    role="button"
                    tabIndex={0}
                    className="DateInput__label"
                    onClick={() => !disabled && this.toggleOpen()}
                >
                    <TextInput
                        id={id}
                        sm={sm}
                        onChange={this.handleTextChange}
                        onKeyPress={this.handleKeyPress}
                        onBlur={this.handleTextBlur}
                        value={textValue}
                        placeholder={placeholder}
                        icon={disabled ? 'lock' : icon || 'date'}
                        disabled={disabled}
                        gray={gray}
                        dark={dark}
                        rounded={rounded}
                        error={error}
                        size={12}
                        setRef={(elem) => {
                            this.initialFocusDiv = elem;
                        }}
                    />
                </div>
                {dateContextMenu}
            </div>
        );
    }
}

DateInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    gray: PropTypes.bool,
    dark: PropTypes.bool,
    rounded: PropTypes.bool,
    sm: PropTypes.bool,
    min: PropTypes.string,
    max: PropTypes.string,
    validations: PropTypes.array,
    precision: PropTypes.string,
};

DateInput.defaultProps = {
    error: false,
    icon: 'date',
    placeholder: '',
    value: '',
    disabled: false,
    gray: false,
    dark: false,
    rounded: false,
    sm: false,
    min: undefined,
    max: undefined,
    onKeyPress: () => {},
    validations: [],
    precision: 'day',
};
