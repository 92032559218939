/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import AutosizeInput from './autosize';

require('./styles.scss');

export default class InputBase extends PureComponent {
    handleChange = (e) => {
        const { onChange, id } = this.props;
        onChange(id, e.target.value);
    };
    render() {
        // we select a subset of props that we want to pass to the input (eg. passing `gray: false` to an input gives a warning)
        const inputProps = {
            autoComplete: this.props.autocomplete,
            autoFocus: this.props.autoFocus,
            className: ClassNames('InputBase', {
                'InputBase--small': this.props.sm,
                'InputBase--autosize': this.props.autosize,
                'InputBase--disabled': this.props.disabled,
            }),
            'data-lpignore': this.props.dataIgnore,
            disabled: this.props.disabled,
            id: this.props.id,
            max: this.props.max,
            min: this.props.min,
            onBlur: this.props.onBlur,
            onChange: this.handleChange,
            onClick: this.props.onClick,
            onFocus: this.props.onFocus,
            onKeyPress: this.props.onKeyPress,
            onSubmit: this.props.onSubmit,
            placeholder: this.props.placeholder,
            size: this.props.size,
            step: this.props.step,
            type: this.props.type,
            value: this.props.value,
            inputMode: this.props.inputMode,
        };
        const { maxWidth } = this.props;
        if (this.props.autosize) {
            const autoSizeProps = {
                placeholderIsMinWidth: this.props.placeholderIsMinWidth,
            };
            if (maxWidth) autoSizeProps.maxWidth = maxWidth - (this.props.icon ? 48 : 24);
            return <AutosizeInput {...inputProps} {...autoSizeProps} />;
        }
        return <input {...inputProps} />;
    }
}
InputBase.propTypes = {
    autocomplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    autosize: PropTypes.bool,
    dataIgnore: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    max: PropTypes.number,
    min: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    onSubmit: PropTypes.func,
    placeholder: PropTypes.string,
    placeholderIsMinWidth: PropTypes.bool,
    size: PropTypes.number,
    sm: PropTypes.bool,
    step: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.number,
    icon: PropTypes.string,
    inputMode: PropTypes.string,
};

InputBase.defaultProps = {
    autocomplete: 'off',
    autoFocus: false,
    autosize: false,
    dataIgnore: undefined,
    disabled: false,
    max: undefined,
    min: undefined,
    onBlur: () => {},
    onChange: () => {},
    onClick: () => {},
    onFocus: () => {},
    onKeyPress: () => {},
    onSubmit: () => {},
    placeholder: null,
    placeholderIsMinWidth: false,
    size: null,
    sm: false,
    step: undefined,
    type: 'text',
    value: '',
    maxWidth: undefined,
    icon: '',
    inputMode: undefined,
};
